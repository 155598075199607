import { Col, Image, Row, Typography } from 'antd';
// import { truncate } from 'lodash';
import React, { useState } from 'react';
import { VideoIcon } from '../../../assets/svg';
import { MEDIA_TYPE } from '../../../common/constants';

const { Paragraph } = Typography;

const ModerationPreview = ({ data }) => {
  const { username, mediaType, caption, mediaUrl, thumbnailUrl } = data || {};
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col
          sm={8}
          lg={6}
          xl={4}
          className="d-flex align-center justify-center"
        >
          {mediaType === MEDIA_TYPE?.VIDEO?.value ? (
            <>
              {thumbnailUrl ? (
                <Image
                  src={thumbnailUrl}
                  alt="post image"
                  className="moderation-preview-img post-aspect-ratio"
                  width={100}
                  preview={false}
                />
              ) : (
                <div className="moderation-video-preview post-aspect-ratio d-flex align-center justify-center">
                  <VideoIcon fill="white" fontSize={20} width={24} />
                </div>
              )}
            </>
          ) : (
            <div>
              <Image
                src={mediaUrl}
                alt="post image"
                className="moderation-preview-img post-aspect-ratio"
                width={100}
                preview={false}
              />
            </div>
          )}
        </Col>
        <Col sm={16} lg={18} xl={20}>
          <Row>
            <Col span={24}>
              <Typography.Title level={4}>{username}</Typography.Title>
            </Col>

            <Paragraph
              ellipsis={{
                rows: 2,
                expandable: 'collapsible',
                expanded,
                symbol: <b>see {expanded ? 'less' : 'more'}</b>,
                onExpand: (_, info) => setExpanded(info.expanded),
              }}
            >
              {caption}
            </Paragraph>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ModerationPreview;
