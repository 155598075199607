import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import WidgetPost from './WidgetPost';

const PostWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.WIDGET} component={WidgetPost} />
  </Switch>
);

export default PostWrapper;
