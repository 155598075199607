import { Col, Image, Row } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { LAYOUT_OPTIONS } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';
import PostPreviewModal from './components/PostPreviewModal';
import ProfilePreviewHeader from './components/ProfilePreviewHeader';
import PostGallery from './PostGallery';
import PostGrid from './PostGrid';
import PostMasonry from './PostMasonry';
import PostSlider from './PostSlider';

function WidgetPreview({
  businessProfile,
  sourceType = '',
  headerData = null,
  fetchMore,
  mediaFetchLimit = null,
  isMobile = false,
  setResponseType,
  sourceData,
  loading: dataLoading,
}) {
  const {
    state: {
      previewStyle,
      headerController,
      previewLayoutObject: { width },
      showHeaders,
      postModule: { postCustomStyle },
      postData,
    },
  } = useContext(AppContext);

  const isSlider = previewStyle === LAYOUT_OPTIONS?.SLIDER?.value;
  const isGrid = previewStyle === LAYOUT_OPTIONS?.GRID?.value;
  const isMasonry = previewStyle === LAYOUT_OPTIONS?.MASONRY?.value;
  const isGallery = previewStyle === LAYOUT_OPTIONS?.GALLERY?.value;

  const modalWrapperRef = useRef();
  const [loading, setLoading] = useState(true);
  const [currentPostId, setCurrentPostId] = useState(null);
  const { id } = useParams();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const wrapperStyle = {
    maxWidth: `${width === 'auto' ? 'unset' : `${width}px`}`,
    margin: '0 auto',
    backgroundColor: postCustomStyle?.isDarkMode ? 'black' : 'unset',
    color: postCustomStyle?.isDarkMode ? 'white' : 'unset',
    fill: postCustomStyle?.isDarkMode ? 'white' : 'unset',
  };

  return (
    <LoaderComponent spinning={dataLoading && postData?.length === 0}>
      <Row
        className="widget-preview"
        style={wrapperStyle}
        ref={modalWrapperRef}
      >
        {showHeaders && (
          <Col span={24}>
            <ProfilePreviewHeader
              headerController={headerController}
              profile={headerData}
              loading={loading}
              setLoading={setLoading}
              businessProfile={businessProfile}
              accountType={sourceType}
              context={AppContext}
            />
          </Col>
        )}
        {openPreviewModal && (
          <PostPreviewModal
            open={openPreviewModal}
            setOpen={setOpenPreviewModal}
            getContainer={modalWrapperRef?.current}
            profile={headerData}
            ProfilePreviewHeader={ProfilePreviewHeader}
            currentPostId={currentPostId}
            context={AppContext}
            headerData={headerData}
            sourceType={sourceType}
            sourceData={sourceData}
            fetchMore={fetchMore}
            widgetId={id}
          />
        )}

        <div
          className="post-wrapper justify-center align-center relative"
          id="modalPreview"
        >
          {isMasonry && (
            <PostMasonry
              profile={headerData}
              fetchMore={fetchMore}
              widgetId={id}
              setOpenPreviewModal={setOpenPreviewModal}
              setCurrentPostId={setCurrentPostId}
              context={AppContext}
              getFetchMoreResponseType={fetchMore}
              isMobile={isMobile}
              hasAccessTo={hasAccessTo}
              sourceType={sourceType}
              sourceData={sourceData}
            />
          )}

          {isSlider && (
            <PostSlider
              profile={headerData}
              widgetId={id}
              setOpenPreviewModal={setOpenPreviewModal}
              setCurrentPostId={setCurrentPostId}
              context={AppContext}
              sourceType={sourceType}
              fetchMore={fetchMore}
              mediaFetchLimit={mediaFetchLimit}
              isMobile={isMobile}
              hasAccessTo={hasAccessTo}
              setResponseType={setResponseType}
              sourceData={sourceData}
            />
          )}
          {isGrid && (
            <PostGrid
              profile={headerData}
              fetchMore={fetchMore}
              widgetId={id}
              setOpenPreviewModal={setOpenPreviewModal}
              setCurrentPostId={setCurrentPostId}
              context={AppContext}
              isMobile={isMobile}
              hasAccessTo={hasAccessTo}
              sourceType={sourceType}
              sourceData={sourceData}
            />
          )}

          {isGallery && (
            <>
              <PostGallery
                profile={headerData}
                fetchMore={fetchMore}
                widgetId={id}
                setOpenPreviewModal={setOpenPreviewModal}
                setCurrentPostId={setCurrentPostId}
                context={AppContext}
                isMobile={isMobile}
                hasAccessTo={hasAccessTo}
                sourceType={sourceType}
                sourceData={sourceData}
              />
            </>
          )}

          {previewStyle === LAYOUT_OPTIONS?.SLIDER?.value &&
            hasAccessTo?.allowWaterMark && (
              <div className="instaplug-watermark slider absolute">
                {
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <a
                    href={process.env.REACT_APP_WEBSITE_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src="/logo.png" preview={false} />
                  </a>
                }
              </div>
            )}
        </div>
      </Row>
    </LoaderComponent>
  );
}

export default WidgetPreview;
