import { Button, Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { GA_EVENT_NAMES, GA_EVENT_PARAMETERS } from '../../common/constants';
import { GaEvent } from '../../common/utils';
import ColRow from './ColRow';
import FeedTitle from './FeedTitle';
import Headers from './Headers';
import SliderSetting from './SliderSetting';

function LayoutController({
  inColRow,
  setInColRow,
  inHeaders,
  setInHeaders,
  inSliderSetting,
  setInSliderSetting,
  inFeedTitle,
  setInFeedTitle,
}) {
  const { state, handleHeaderController } = useContext(AppContext);
  const { id } = useParams();
  const { headerController, previewStyle } = state;
  const [breakpoint, setBreakpoint] = useState(false);
  const LayoutChanger = inColRow || inHeaders || inSliderSetting || inFeedTitle;
  const handleBack = (section) => {
    GaEvent(section, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
    switch (section) {
      case GA_EVENT_NAMES.BACK_BUTTON_COLUMN_ROW:
        setInColRow(false);
        break;
      case GA_EVENT_NAMES.BACK_BUTTON_HEADER:
        setInHeaders(false);
        break;
      case GA_EVENT_NAMES.BACK_BUTTON_SLIDER_SETTINGS:
        setInSliderSetting(false);
        break;
      case GA_EVENT_NAMES.BACK_BUTTON_FEED_TITLE:
        setInFeedTitle(false);
        break;
      default:
        break;
    }
    handleHeaderController(headerController);
  };

  const handleBreakPoint = () => {
    setBreakpoint(true);
  };

  return (
    <div>
      {LayoutChanger && (
        <Row gutter={8} justify="space-between">
          {!breakpoint && (
            <>
              <Col span={16}>
                {inColRow && <h2>Column and Row</h2>}
                {inHeaders && <h2>Headers</h2>}
                {inSliderSetting && <h2>Slider Setting</h2>}
                {inFeedTitle && <h2>Feed Title</h2>}
              </Col>
              <Col>
                <Button
                  danger
                  type="primary"
                  onClick={() => {
                    if (inColRow) {
                      handleBack(GA_EVENT_NAMES.BACK_BUTTON_COLUMN_ROW);
                    } else if (inHeaders) {
                      handleBack(GA_EVENT_NAMES.BACK_BUTTON_HEADER);
                    } else if (inSliderSetting) {
                      handleBack(GA_EVENT_NAMES.BACK_BUTTON_SLIDER_SETTINGS);
                    } else if (inFeedTitle) {
                      handleBack(GA_EVENT_NAMES.BACK_BUTTON_FEED_TITLE);
                    }
                  }}
                  className=" all-btn mt-16"
                >
                  Back
                </Button>
              </Col>
            </>
          )}
          <Col span={24}>
            {inColRow && (
              <>
                <ColRow
                  handleBreakPoint={handleBreakPoint}
                  breakpoint={breakpoint}
                  setBreakpoint={setBreakpoint}
                />
              </>
            )}
            {inHeaders && (
              <>
                <Headers />
              </>
            )}
            {inSliderSetting && (
              <>
                <SliderSetting />
              </>
            )}
            {inFeedTitle && (
              <>
                <FeedTitle />
              </>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default LayoutController;
