import { gql } from '@apollo/client';

const PERSONAL_ACCOUNT_CONNECT = gql`
  mutation ConnectPersonalAccount($data: ConnectToPersonalAccountInput!) {
    connectPersonalAccount(data: $data) {
      message
      sourceId
    }
  }
`;
export default PERSONAL_ACCOUNT_CONNECT;
export const DISCONNECT_INSTAGRAM_ACCOUNT = gql`
  mutation DisconnectAccount($where: DisconnectAccountInput!) {
    disconnectAccount(where: $where) {
      message
    }
  }
`;

export const CONNECT_BUSINESS_ACCOUNT = gql`
  mutation ConnectBusinessAccount($data: ConnectToBusinessAccountInput!) {
    connectBusinessAccount(data: $data) {
      message
      sourceId
    }
  }
`;
export const FILTERS_MEDIA = gql`
  mutation CreateOrUpdateFilters($data: CreateOrUpdateFiltersInput!) {
    createOrUpdateFilters(data: $data) {
      show
      hide
      totalNumberOfPost
    }
  }
`;

export const CHANGE_BUSINESS_SOURCE = gql`
  mutation ChangeBusinessSource($data: ChangeBusinessSourceInput!) {
    changeBusinessSource(data: $data) {
      sourceId
      sourceType
      igSources
      widgetId
      businessSources {
        id
        username
        isSelected
      }
    }
  }
`;
