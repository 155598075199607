import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { MINUTES, ROUTES, SECONDS } from '../../common/constants';
import Timer from '../../components/Timer';
import { CHANGE_EMAIL, VERIFY_NEW_EMAIL } from '../auth/graphql/Mutations';

const { Title } = Typography;
function VerifyEmail() {
  const [form] = Form?.useForm();
  const history = useHistory();
  const { getCurrentUser, setCurrentUser } = useContext(AppContext);
  const getUser = getCurrentUser();

  const location = useLocation();
  const { email } = location?.state || {};
  const [seconds, setSeconds] = useState(SECONDS);
  const [minutes, setMinutes] = useState(MINUTES);
  const [timerComplete, setTimerComplete] = useState(false);

  useEffect(() => {
    // if email is not in state it will go back to previous route
    if (!email) {
      history.goBack();
    }
  }, []);

  const [verifyNewEmail, { loading: verifyMailLoading }] = useMutation(
    VERIFY_NEW_EMAIL,
    {
      onCompleted(res) {
        const { user } = res?.updateEmailAddress || {};
        setCurrentUser(user);
        history.push(`${ROUTES?.PROFILE}/${user?.id}`);
      },
      onError() {},
    },
  );

  // here, verify new email is use for resend otp
  const [changeEmail] = useMutation(CHANGE_EMAIL, {
    onCompleted() {},
    onError() {},
  });
  const handleVerifySignUp = async (values) => {
    try {
      const formValues = {
        newEmail: email,
        otp: values?.otp,
        userId: getUser?.id,
      };
      verifyNewEmail({
        variables: {
          data: {
            ...formValues,
          },
        },
      });

      form?.setFieldsValue(values);
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from verify new email => ', error);
    }
  };
  const handleResendOTP = () => {
    changeEmail({
      variables: {
        data: {
          newEmail: email,
          userId: getUser?.id,
        },
      },
    });
  };

  return (
    <div className="auth-bg d-flex">
      <div className="login-wrap align-center justify-start d-flex">
        <Spin spinning={verifyMailLoading} wrapperClassName="full-width">
          <Title>
            <center>
              <img src="/logo.png" alt="svg logo" className="auth-logo" />
            </center>
          </Title>
          <Card>
            <div className="d-flex justify-center align-center font-bold font-2xl mt-12">
              Verify New Email
            </div>
            <p>
              Please enter 6 digit code sent to <b>{email}</b>
            </p>
            <Form onFinish={handleVerifySignUp} form={form}>
              <Form.Item name="otp">
                <Input.Password maxLength={6} />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="full-width all-btn"
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
            <div>
              {timerComplete ? (
                <div className="d-flex align-center">
                  <p>Didn't received OTP?</p>
                  <Button size="small" type="link" onClick={handleResendOTP}>
                    <u>Click here!</u>
                  </Button>
                </div>
              ) : (
                <div className="d-flex align-center">
                  <p>Resend OTP in</p>
                  <p className="ml-8">
                    <Timer
                      seconds={seconds}
                      minutes={minutes}
                      setMinutes={setMinutes}
                      setSeconds={setSeconds}
                      setTimerComplete={setTimerComplete}
                    />
                  </p>
                </div>
              )}
            </div>
          </Card>
        </Spin>
      </div>
    </div>
  );
}

export default VerifyEmail;
