import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Error404 from '../../Error404';
import { ROUTES } from '../../common/constants';
import CommonLogo from '../../components/CommonLogo';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import PERSONAL_ACCOUNT_CONNECT, {
  CONNECT_BUSINESS_ACCOUNT,
} from './graphql/Mutations';

function InstagramConnect() {
  const history = useHistory();
  const { getToken, getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const userToken = getToken();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const code = params?.get('code');
  const accountState = params?.get('state');
  const widgetId = userToken ? accountState?.split('/')?.[0] : null;
  const account = userToken ? accountState?.split('/')?.[1] : null;
  const stateParseData =
    isEmpty(account) && accountState ? JSON.parse(accountState) : null;
  const [loading, setLoading] = useState(false);

  const restUrl = `${process.env.REACT_APP_SERVER_REST_URL}/api/v1/wordpress-plugin/verify-auth-code`;

  /* set data for rest api */
  const postData = {
    authorizationCode: code,
    currentUrl: stateParseData?.currentUrl,
    redirectUri: process.env.REACT_APP_WORDPRESS_REDIRECT_URI,
    accountType: stateParseData?.accountType,
    widgetId: stateParseData?.widgetId,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${stateParseData?.accessToken}`,
    },
    body: JSON.stringify(postData),
  };

  const [connectAccount, { loading: connectAccountLoading }] = useMutation(
    account === 'instagram'
      ? PERSONAL_ACCOUNT_CONNECT
      : CONNECT_BUSINESS_ACCOUNT,
    {
      onCompleted(res) {
        const accountDataKey =
          account === 'instagram'
            ? 'connectPersonalAccount'
            : 'connectBusinessAccount';
        if (res?.[accountDataKey]?.sourceId) {
          // eslint-disable-next-line no-undef
          window?.close();
        }
      },
      onError() {
        // eslint-disable-next-line no-undef
        window?.close();
      },
    },
  );

  useEffect(() => {
    (async () => {
      /* check if account contains data or not */
      if (!isEmpty(account) && userToken) {
        await connectAccount({
          variables: {
            data: {
              authorizeCode: code,
              widgetId,
              redirectUrl: process.env.REACT_APP_REDIRECT_URI,
            },
          },
        });
      } else {
        setLoading(true);
        if (stateParseData?.accessToken) {
          fetch(restUrl, requestOptions)
            .then((response) => {
              if (!response.ok) {
                throw new Error('REST network request error!');
              }
              return response.json();
            })
            .then((responseData) => {
              const { currentUrl, token } = responseData?.data || {};
              const redirectUrl = `${currentUrl}&token=${token}`;
              if (redirectUrl) {
                // eslint-disable-next-line no-undef
                window.location.href = redirectUrl;
                setLoading(false);
              }
            })
            .catch((error) => {
              message.error(error?.message);
              setLoading(false);
            })
            .finally(() => {
              setLoading(false);
            });
        } else if (isEmpty(user)) {
          setLoading(false);
          history?.push(ROUTES.LOGIN);
        } else {
          setLoading(false);
          return null;
        }
      }
    })();
  }, []);

  if (connectAccountLoading || loading) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <Portal portalId="header-title">
        <CommonLogo />
      </Portal>

      <div className="d-flex justify-center align-center h-screen">
        {!stateParseData?.accessToken && <Error404 />}
      </div>
    </div>
  );
}

export default InstagramConnect;
