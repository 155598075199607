import { FilterOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Radio, Row, Space } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  SOURCE_TYPE,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';
import BusinessAccount from './BusinessAccount';
import Filters from './Filters';
import PersonalAccount from './PersonalAccount';
import { FILTERS_MEDIA } from './graphql/Mutations';

function Source({
  setData,
  accountType,
  sourceData,
  accountMedia,
  setAccountMedia,
  getSource,
  headerData,
  setHeaderData,
  loading = false,
  getHeader,
  getBusinessProfile,
  personalHeaderLoading,
  disconnectAccount,
  addedData,
  setAddedData,
  widgetData = null,
  sourceType,
  setSourceType,
  getBusinessLoading,
  setSourceData,
  setFilters,
  filters = { show: [], hide: [] },
  businessSources = [],
  setBusinessSources,
}) {
  const { id } = useParams();
  const isFirstRender = useRef(true);
  const [showFilters, setShowFilters] = useState(false);
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setAccountMedia([]);
    setHeaderData([]);
    setSourceType(value);
    setSourceData(null);
    getSource({
      variables: {
        where: {
          sourceType: value,
          widgetId: id,
        },
      },
    });
  };

  const [updateFilters] = useMutation(FILTERS_MEDIA, {
    fetchPolicy: 'network-only',
    onCompleted() {
      getSource({
        variables: {
          where: {
            sourceType: accountType,
            widgetId: id,
          },
        },
      });
    },
  });

  useEffect(() => {
    if (isFirstRender?.current) {
      isFirstRender.current = false;
    }
  }, []);

  const handleFilters = () => {
    setShowFilters(true);
    GaEvent(GA_EVENT_NAMES.BACK_BUTTON_FILTER, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
  };

  return (
    <div className="text-color">
      {!showFilters ? (
        <>
          <h2>Source</h2>
          <Space direction="vertical">
            <h3>Source type</h3>
            <Radio.Group onChange={handleChange} value={sourceType}>
              <Row gutter={[8, 8]}>
                {map(SOURCE_TYPE, (source) => (
                  <Col key={source?.value}>
                    <Radio value={source?.value} className="source-container">
                      {source?.name}
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
            {sourceType ? (
              <>
                <Space>
                  <h3 className="source-container">Instagram sources</h3>
                </Space>
              </>
            ) : null}

            {sourceType === SOURCE_TYPE?.BUSINESS_ACCOUNT?.value && (
              <BusinessAccount
                accountType={accountType}
                sourceData={sourceData}
                accountMedia={accountMedia}
                setAccountMedia={setAccountMedia}
                headerData={headerData}
                setHeaderData={setHeaderData}
                loading={loading}
                getHeader={getHeader}
                getBusinessProfile={getBusinessProfile}
                disconnectAccount={disconnectAccount}
                getSource={getSource}
                getBusinessLoading={getBusinessLoading}
                businessSources={businessSources}
                setBusinessSources={setBusinessSources}
              />
            )}
            {sourceType === SOURCE_TYPE?.PERSONAL_ACCOUNT?.value && (
              <PersonalAccount
                accountType={accountType}
                sourceData={sourceData}
                accountMedia={accountMedia}
                setAccountMedia={setAccountMedia}
                headerData={headerData}
                setHeaderData={setHeaderData}
                loading={loading}
                getHeader={getHeader}
                personalHeaderLoading={personalHeaderLoading}
                disconnectAccount={disconnectAccount}
                getSource={getSource}
              />
            )}
          </Space>
        </>
      ) : (
        <Filters
          id={id}
          setShowFilters={setShowFilters}
          setData={setData}
          filters={filters}
          setFilters={setFilters}
          updateFilters={updateFilters}
          addedData={addedData}
          setAddedData={setAddedData}
          widgetData={widgetData}
        />
      )}

      {!showFilters && filters && (
        <div className="mt-8">
          <Button
            onClick={handleFilters}
            icon={<FilterOutlined />}
            className="full-width all-btn feed-info-card"
            type="primary"
            size="large"
          >
            Filters
          </Button>
        </div>
      )}
    </div>
  );
}

export default Source;
