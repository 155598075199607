import { Typography } from 'antd';
import React from 'react';

function isURL(str) {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlPattern?.test(str);
}

const DisplayTextWithLinks = ({ text }) => {
  if (text?.charAt(0) === '@') {
    return (
      <Typography.Link
        href={`https://www.instagram.com/${text?.slice(1)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="font-2xl"
      >
        {text}
      </Typography.Link>
    );
  }

  if (text?.charAt(0) === '#') {
    return (
      <Typography.Link
        href={`https://www.instagram.com/explore/tags/${text?.slice(1)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="font-2xl"
      >
        {text}
      </Typography.Link>
    );
  }

  if (isURL(text)) {
    return (
      <Typography.Link
        href={text}
        target="_blank"
        rel="noopener noreferrer"
        className="font-2xl"
      >
        {text}
      </Typography.Link>
    );
  }

  return text;
};

export default DisplayTextWithLinks;
