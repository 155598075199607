import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import history from '../../historyData';
import { REFRESH_TOKEN } from './graphql/Mutations';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const { pathname } = history?.location;
  const refreshToken = getRefreshToken();

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted({ newAccessToken }) {
      if (newAccessToken) {
        initializeAuth(newAccessToken);
        if (pathname === ROUTES.AUTHENTICATION) {
          history?.push(ROUTES.MAIN);
          return;
        }
        history?.goBack();
      }
    },
    onError() {
      history?.replace(ROUTES.LOGOUT);
    },
  });

  useEffect(() => {
    refresh({
      variables: {
        refreshToken,
      },
    });
  }, []);

  return <LoaderComponent spinning={loading} />;
};

export default RefreshToken;
