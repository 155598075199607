import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PRICING_PLAN_LIST = gql`
  query SubscriptionPlans {
    subscriptionPlans {
      id
      features
      billingAmount
      currency
      billingType
      productId
      productPriceId
      key
      createdAt
      updatedAt
    }
  }
`;
