import { FacebookFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { PremiumIcon } from '../../assets/svg';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  POPUP_FEATURES,
  SOURCE_TYPE,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';
import { CHANGE_BUSINESS_SOURCE } from './graphql/Mutations';

const { Option } = Select;

const FACEBOOK_CONNECT_URL = `https://www.facebook.com/v3.3/dialog/oauth?client_id=766105421693364&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=email,pages_show_list,business_management,instagram_basic&response_type=code`;
function BusinessAccount({
  accountType,
  headerData,
  sourceData,
  disconnectAccount,
  getSource,
  getBusinessLoading,
  businessSources = [],
  setBusinessSources,
}) {
  const {
    state: { openFeatureAccessModal },
    toggleFeatureAccessModal,
  } = useContext(AppContext);
  const { id } = useParams();
  const url = `${FACEBOOK_CONNECT_URL}&state=${id}/facebook`;
  const features = POPUP_FEATURES;
  const [childWindow, setChildWindow] = useState(null);
  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const connectBusinessAccount = () => {
    // eslint-disable-next-line no-undef
    const newChildWindow = window?.open(url, 'popUpWindow', features);
    setChildWindow(newChildWindow);
    GaEvent(GA_EVENT_NAMES.CONNECT_FACEBOOK_BUSINESS_ACCOUNT, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
  };

  const [changeBusinessSource] = useMutation(CHANGE_BUSINESS_SOURCE, {
    onCompleted(res) {
      setBusinessSources(res?.changeBusinessSource?.businessSources);
    },
    onError() {},
  });

  const onBusinessSourceChange = (value) => {
    changeBusinessSource({
      variables: {
        data: {
          sourceId: sourceData?.sourceId,
          sourceUsername: value,
        },
      },
    });
  };

  const handleDisconnectAccount = () => {
    disconnectAccount({
      variables: {
        where: {
          widgetId: id,
          sourceType: accountType,
        },
      },
    });
  };

  const IsConnected =
    sourceData &&
    sourceData?.sourceType === SOURCE_TYPE?.BUSINESS_ACCOUNT?.value;

  useEffect(() => {
    if (IsConnected) {
      return;
    }
    const interval = setInterval(() => {
      if (childWindow && childWindow?.closed) {
        setChildWindow(null);
        getSource({
          variables: {
            where: {
              sourceType: accountType,
              widgetId: id,
            },
          },
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [childWindow, sourceData]);

  const defaultSelectedOption = useMemo(
    () => businessSources?.find((option) => option?.isSelected),
    [businessSources],
  );

  return (
    <Card
      title="Facebook Connect"
      className="feed-info-card"
      actions={[
        <Button
          key="business"
          type="primary"
          icon={
            <div className="d-flex mr-6 align-center col-gap-4">
              {!hasAccessTo?.allowIGBusinessFeed && (
                <PremiumIcon className="h-16" />
              )}
              <FacebookFilled />
            </div>
          }
          className="all-btn d-flex align-center m-auto"
          onClick={() => {
            if (!hasAccessTo?.allowIGBusinessFeed) {
              toggleFeatureAccessModal({
                ...openFeatureAccessModal,
                status: true,
                info: 'Connect to your facebook business account.',
              });
              return;
            }
            if (IsConnected) {
              handleDisconnectAccount();
            } else {
              connectBusinessAccount();
            }
          }}
        >
          {IsConnected ? 'Disconnect Account' : 'Facebook Account'}
        </Button>,
      ]}
    >
      <LoaderComponent spinning={getBusinessLoading} setHeight={10}>
        <Content>
          {IsConnected ? (
            <span>
              Successfully connected as
              <strong>
                <u> {headerData?.username || headerData?.name} </u>
              </strong>
              <div className="mt-8 full-width">
                <Select
                  className="full-width"
                  placeholder="Instagram Account"
                  onChange={onBusinessSourceChange}
                  defaultValue={
                    defaultSelectedOption
                      ? defaultSelectedOption?.username
                      : undefined
                  }
                >
                  {map(businessSources, (source) => (
                    <Option key={source?.id} value={source?.username}>
                      @{source?.username}
                    </Option>
                  ))}
                </Select>
              </div>
            </span>
          ) : (
            'Authorize in your Facebook account that has access to the Facebook Business page linked to your Instagram business account.'
          )}
        </Content>
      </LoaderComponent>
    </Card>
  );
}

export default BusinessAccount;
