import { useContext, useEffect, useState } from 'react';
import { LIMIT_KEYS, PLANS } from '../../../common/constants';

const useFeatureAccess = ({ contextToUse }) => {
  const {
    state: { currentUser },
  } = useContext(contextToUse) || {};

  const initialAccessConfig = {
    allowWidgetCreation: false,
    allowCommentLikeCount: false,
    allowPostPopup: false,
    allowPostHoverEffect: false,
    allowSharingOfPost: false,
    allowHashtagAndMentions: false,
    allowAdjustableWidgetWidth: true,
    allowResponsive: true,
    allowWaterMark: true,
    allowMultipleLayout: true,
    allowCTA: true,
    allowAccountInfoWithFollowButton: true,
    allowCarouselReelSupport: true,
    allowWebsiteAddLimit: true,
    viewLimitReached: false,
    allowIGBusinessFeed: true,
    allowIGPersonalFeed: true,
    updateTime: '',
    allowCustomCss: false,
    allowNewLayout: false,
    allowPostModeration: false,
  };

  const [widgetAccessConfig, setWidgetAccessConfig] = useState(
    initialAccessConfig,
  );
  const [widgetLimitAndUsage, setWidgetLimitAndUsage] = useState(null);

  useEffect(() => {
    const result = {};

    currentUser?.subscriptionFeatures?.forEach((item) => {
      const { key } = item;
      result[key] = {
        limit: item?.limit?.value,
        usage: item?.usage?.value,
      };
    });
    const updateAccessConfig = {};

    Object?.entries(LIMIT_KEYS)?.forEach(([limitKey, accessKey]) => {
      const limitValue = result[limitKey]?.limit;
      const usageValue = result[limitKey]?.usage;

      switch (limitKey) {
        case 'WEBSITE_ADD_LIMIT':
          updateAccessConfig[accessKey?.key] =
            limitValue === 'Unlimited' ||
            Number(limitValue) !== Number(usageValue);
          break;
        case 'WIDGET_CREATION_LIMIT':
          updateAccessConfig[accessKey?.key] =
            limitValue === 'Unlimited' ||
            (currentUser?.subscriptionPlan?.key !==
              PLANS?.AGENCY_LIFE_TIME_DEAL?.key &&
              Number(usageValue) >= Number(limitValue));

          break;
        case 'NUMBER_OF_VIEWS':
          updateAccessConfig[accessKey?.key] =
            Number(limitValue) === Number(usageValue);
          break;
        default:
          updateAccessConfig[accessKey?.key] = limitValue;
          break;
      }
    });
    setWidgetLimitAndUsage(result);
    setWidgetAccessConfig((prevConfig) => ({
      ...prevConfig,
      ...updateAccessConfig,
    }));
  }, [currentUser?.subscriptionFeatures]);

  return { widgetAccessConfig, widgetLimitAndUsage };
};

export default useFeatureAccess;
