import { blue, generate, green, presetPalettes, red } from '@ant-design/colors';
import { Col, ColorPicker, Divider, Row, theme } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../AppContext';

const generatePresetColors = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));
const CustomColorPicker = () => {
  const {
    state: { postModule },
    handlePostModule,
  } = useContext(AppContext);
  const { postCustomStyle } = postModule;
  const { token } = theme.useToken();
  const presets = generatePresetColors({
    primary: generate(token?.colorPrimary),
    red,
    green,
    blue,
  });

  const customPanelRender = (_, { components: { Picker, Presets } }) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider
        type="vertical"
        style={{
          height: 'auto',
        }}
      />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );
  const handleColorChange = (selectedColor, hex) => {
    handlePostModule({
      ...postModule,
      postCustomStyle: { ...postCustomStyle, fontColor: hex },
    });
  };

  return (
    <ColorPicker
      onChange={handleColorChange}
      defaultValue={postCustomStyle?.fontColor}
      presets={presets}
      panelRender={customPanelRender}
    />
  );
};

export default CustomColorPicker;
