import { HeartOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Carousel, Image } from 'antd';
import { filter, forEach, includes, isEmpty, isNumber, map } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CommentIcon,
  MultiplePostIcon,
  NextIcon,
  PrevIcon,
  VideoIcon,
} from '../../assets/svg';
import {
  BREAKPOINTS,
  COL_ROW_OPTIONS,
  MEDIA_TYPE,
  POST_ACTION,
  POST_ELEMENTS,
  POST_STYLE,
} from '../../common/constants';
import { convertToCompactFormat } from '../../common/utils';
import PostFooter from './components/PostFooter';
import PostHeader from './components/PostHeader';

function PostSlider({
  setOpenPreviewModal,
  setCurrentPostId,
  profile,
  isMobile = false,
  hasAccessTo,
  darkMode = false,
  context,
}) {
  const {
    state: {
      previewLayoutObject: { col, row, gap },
      sliderSettings: { arrowControl, dragControl, animationSpeed, autoPlay },
      postModule: { style, postElement, clickAction, postCustomStyle },
      postData,
      columnMode,
    },
  } = useContext(context);

  const { borderColor, borderStyle, borderWidth, fontColor, fontFamily } =
    postCustomStyle || {};

  const isHover =
    hasAccessTo?.allowPostHoverEffect && style === POST_STYLE?.HOVER?.value;
  const settingFontColor = fontColor;
  const previewRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const returnMobileLayout = useMemo(() => {
    if (isMobile) {
      return 1;
    }
    return columnMode === COL_ROW_OPTIONS?.AUTO?.value ? 4 : col;
  }, [isMobile, columnMode, col]);

  const settings = useMemo(
    () => ({
      className: 'post-slider common-carousel',
      speed: 500,
      slidesPerRow: 1,
      dots: false,
      slidesToScroll: returnMobileLayout,
      slidesToShow: returnMobileLayout,
      rows: row,
      arrows: arrowControl,
      draggable: dragControl || false,
      ...(isNumber(animationSpeed) && { speed: +animationSpeed * 1000 }),
      ...(isNumber(autoPlay) && {
        autoplaySpeed: +autoPlay * 1000,
        autoplay: !!+autoPlay,
      }),
      responsive: [
        {
          breakpoint: BREAKPOINTS?.mobile,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: BREAKPOINTS?.tablet,
          settings: {
            slidesToShow: col < 3 ? col : 3,
            slidesToScroll: col < 3 ? col : 3,
          },
        },
      ],
    }),
    [
      col,
      row,
      returnMobileLayout,
      arrowControl,
      dragControl,
      animationSpeed,
      autoPlay,
    ],
  );

  const controlPostElement = (section) => includes(postElement, section);
  const POST_HEADER_ELEMENT = ['user', 'date', 'instagramLink'];

  const isHeaderElementSelected = POST_HEADER_ELEMENT?.some((value) =>
    postElement?.includes(value),
  );

  const isPostFooterElementSelected = filter(
    POST_ELEMENTS,
    ({ value }) => !POST_HEADER_ELEMENT?.includes(value),
  )?.some(({ value }) => postElement?.includes(value));

  const dynamicStyle = {
    gap: `${gap}px`,
    color: darkMode ? 'white' : 'unset',
  };

  useEffect(() => {
    if (postData?.length === 0 && !previewRef?.current) return;
    const slickSlideElements =
      previewRef?.current?.innerSlider?.list?.children?.[0]?.children;

    forEach(slickSlideElements, (slickSlides) => {
      forEach(slickSlides?.children, (slides) => {
        // eslint-disable-next-line no-param-reassign
        slides.parentElement.style.display = 'flex';
        // eslint-disable-next-line no-param-reassign
        slides.parentElement.style.flexDirection = 'column';
        // eslint-disable-next-line no-param-reassign
        slides.parentElement.style.gap = `${gap}px`;
        // eslint-disable-next-line no-param-reassign
        slides.parentElement.style.margin = `0px ${gap / 2}px`;
      });
    });
  }, [gap, dynamicStyle]);

  const handleClickOnPost = useCallback(
    (_event, post) => {
      setCurrentPostId(post?.id);

      if (isDragging) return;

      if (clickAction === POST_ACTION?.POPUP?.value) {
        setOpenPreviewModal(true);
        return;
      }

      if (clickAction === POST_ACTION?.INSTAGRAM?.value) {
        // eslint-disable-next-line no-undef
        window?.open(post?.permalink, '_blank');
        return;
      }

      return false;
    },
    [isDragging, clickAction],
  );

  const handleBeforeChange = useCallback(() => {
    if (!isDragging) {
      setIsDragging(true);
    }
  }, [isDragging, setIsDragging]);

  const isLikeCount = (item) => {
    const conditionStatus =
      controlPostElement('likeCount') &&
      'likeCount' in item &&
      item?.commentsCount !== null &&
      hasAccessTo?.allowCommentLikeCount;
    return conditionStatus;
  };

  const isCommentCount = (item) => {
    const conditionStatus =
      controlPostElement('commentCount') &&
      'commentsCount' in item &&
      item?.commentsCount !== null &&
      hasAccessTo?.allowCommentLikeCount;
    return conditionStatus;
  };

  const isCaption = controlPostElement('text') && col <= 7;

  return (
    !isEmpty(postData) && (
      <Carousel
        ref={previewRef}
        {...settings}
        nextArrow={
          <Button
            className="next-arrow d-flex align-center full-height"
            type="text"
            icon={
              <div className="carousel-next-btn">
                <NextIcon />
              </div>
            }
            iconPosition="start"
          />
        }
        prevArrow={
          <div>
            <div className="prev-arrow d-flex align-center full-height">
              <PrevIcon />
            </div>
          </div>
        }
        style={dynamicStyle}
        beforeChange={handleBeforeChange}
      >
        {map(postData, (item) => (
          <div key={item?.id}>
            <div
              style={{
                borderWidth,
                borderColor,
                borderStyle,
              }}
              className={`post-wrapper  ${!isHover ? 'add-border' : ''}`}
            >
              {!isHover && isHeaderElementSelected && (
                <PostHeader
                  profile={profile}
                  postData={item}
                  controlPostElement={controlPostElement}
                  col={col}
                  context={context}
                />
              )}

              <div
                className="post pointer d-flex justify-center post-aspect-ratio"
                onClickCapture={(e) => handleClickOnPost(e, item)}
              >
                {item?.children && (
                  <div className="video-icon">
                    <MultiplePostIcon />
                  </div>
                )}
                {item?.mediaType === MEDIA_TYPE?.VIDEO?.value ? (
                  <>
                    <div className="video-icon">
                      <VideoIcon />
                    </div>
                    {item?.thumbnailUrl ? (
                      <Image
                        className="instagram-post-thumbnail"
                        src={item?.thumbnailUrl}
                        preview={false}
                      />
                    ) : (
                      <PlayCircleOutlined className="text-color font-3xl" />
                    )}
                  </>
                ) : (
                  <Image
                    className="instagram-post-image"
                    src={item?.mediaUrl}
                    preview={false}
                  />
                )}
                {isHover && isPostFooterElementSelected && (
                  <div
                    style={{ color: settingFontColor, fontFamily }}
                    className="post-overlay-info d-flex flex-vertical align-center justify-center"
                    onClickCapture={(e) => handleClickOnPost(e, item)}
                  >
                    <div className="post-overlay-inner-wrapper d-flex mb-12">
                      {isLikeCount(item) && (
                        <div className="d-flex mr-12 col-gap-4">
                          <span>
                            <HeartOutlined className="post-overlay-icon" />
                          </span>
                          <span className="font-m">
                            {convertToCompactFormat(item?.likeCount)}
                          </span>
                        </div>
                      )}

                      {isCommentCount(item) && (
                        <div className="d-flex col-gap-4">
                          <span>
                            <CommentIcon
                              className="post-overlay-icon"
                              fill={fontColor}
                            />
                          </span>
                          <span className="font-m">
                            {convertToCompactFormat(item?.commentsCount)}
                          </span>
                        </div>
                      )}
                    </div>

                    {isCaption && (
                      <div className="post-caption  pl-12 pr-12">
                        <p className="text-center font-m">{item?.caption}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {!isHover && isPostFooterElementSelected && (
                <PostFooter
                  postData={item}
                  controlPostElement={controlPostElement}
                  col={col}
                  context={context}
                />
              )}
            </div>
          </div>
        ))}
      </Carousel>
    )
  );
}

export default PostSlider;
