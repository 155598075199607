import { useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES, USER } from './common/constants';
import USER_PROFILE from './modules/auth/graphql/Queries';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { getToken, setCurrentUser } = useContext(AppContext);
  const idToken = getToken();
  const { data } = useQuery(USER_PROFILE);
  const user = data?.userProfile;

  useEffect(() => {
    if (idToken && user) {
      const userObj =
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null;
      // eslint-disable-next-line no-undef
      localStorage.setItem(USER, userObj);
      setCurrentUser(user);
    }
  }, [user, idToken]);

  return (
    <Route
      {...rest}
      render={(props) =>
        !idToken ? <Redirect to={ROUTES?.LOGIN} /> : <Component {...props} />
      }
    />
  );
};
export default PrivateRoute;
