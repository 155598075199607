import copyCode from '../assets/images/copy-code.png';
import createWidget from '../assets/images/create-widget.png';
import customizeWidget from '../assets/images/customize-widget.png';
import embedCode from '../assets/images/embed-code.png';
import instagramConnect from '../assets/images/instagram-connect.png';
import publishWidget from '../assets/images/publish-widget.png';
import signup from '../assets/images/sign-up.png';

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  VERIFY_SIGNUP: '/verify-signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  TABLE: '/tables',
  SAMPLE: '/sample',
  PROFILE: '/profile',
  RESETPOPUP: '/resetpopup',
  WIDGET: '/widget',
  SOURCE: '/source',
  LAYOUT: '/layout',
  POST: '/post',
  INSTAGRAM_CONNECT: '/igcode',
  MANAGE_PRICING: '/manage-pricing',
  SUCCESS: '/success',
  FAIL: '/fail',
  WORDPRESS_CONNECT: '/wordpress-connect',
  VERIFY_NEW_EMAIL: '/verify-email',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  SAMPLE: 'Sample',
  SOURCE: 'source',
  LAYOUT: 'layout',
  POST: 'post',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const EXTENSIONS_CODE = 'extensions_code';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

/* Date and time */
export const defaultDateFormat = 'DD-MM-YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+$/,
  EMAILWITHPLUS: /^[a-zA-Z0-9.+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  USERNAME: /^@|^#/i,
};

export const LIMIT = 10;
export const OFFSET = 0;
export const SCROLL_PERCENT = 0.85;

export const ORDER = { ASC: 'ASC', DESC: 'DESC' };
export const PAGINATION_SORT = {
  sortOn: { CAPTION: 'caption', TIMESTAMP: 'timestamp' },
  sortBy: { ASC: 'ASC', DESC: 'DESC' },
};

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const LAYOUT_OPTIONS = {
  SLIDER: {
    label: 'Slider',
    value: 'SLIDER',
  },
  GRID: {
    label: 'Grid',
    value: 'GRID',
  },
  MASONRY: {
    label: 'Masonry',
    value: 'MASONRY',
  },
  GALLERY: {
    label: 'Gallery',
    value: 'GALLERY',
  },
};

export const COL_ROW_OPTIONS = {
  AUTO: { label: 'Auto', value: 'AUTO' },
  MANUAL: { label: 'Manual', value: 'MANUAL' },
};

export const POPUP_LIST = {
  user: { label: 'User', value: 'user' },
  location: { label: 'Location', value: 'location' },
  followButton: { label: 'Follow Button', value: 'followButton' },
  instagramLink: { label: 'Instagram Link', value: 'instagramLink' },
  likesCount: { label: 'Like count', value: 'likesCount' },
  share: { label: 'Share', value: 'share' },
  text: { label: 'Text', value: 'text' },
  date: { label: 'Date', value: 'date' },
  comments: { label: 'Comments', value: 'comments' },
};

export const HOVER_POST_ELEMENTS = {
  likeCount: { name: 'Like Count', value: 'likeCount' },
  commentCount: { name: 'Comments Count', value: 'commentCount' },
  text: { name: 'Text', value: 'text' },
};

export const POST_ELEMENTS = {
  user: { name: 'User', value: 'user' },
  date: { name: 'Date', value: 'date' },
  instagramLink: { name: 'Instagram Link', value: 'instagramLink' },
  share: { name: 'Share', value: 'share' },
  ...HOVER_POST_ELEMENTS,
};

export const POST_OPTIONS = [
  { value: 'Classic', label: 'Classic' },
  { value: 'Tile', label: 'Tile' },
];

export const POST_ACTION = {
  POPUP: { value: 'POPUP', label: 'In Pop up' },
  INSTAGRAM: { value: 'INSTAGRAM', label: 'In Instagram' },
  NONE: { value: 'NONE', label: 'None' },
};
export const AUTO_PLAY_OPTIONS = [
  { label: 'OFF', value: 0 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
];
export const HEADERS_TITLE = {
  profilePicture: { label: 'Profile Picture', value: 'profilePicture' },
  fullName: { label: 'Full Name', value: 'fullName' },
  userName: { label: 'Username', value: 'userName' },
  verifiedBadge: { label: 'Verified Badge', value: 'verifiedBadge' },
  postsCount: { label: 'Post Count', value: 'postsCount' },
  followersCount: { label: 'Follower Count', value: 'followersCount' },
  followingCount: { label: 'Following Count', value: 'followingCount' },
  followButton: { label: 'Follow Button', value: 'followButton' },
};

export const PUBLIC = 'public';
export const BUSINESS = 'business';
export const PERSONAL = 'personal';

export const CLASSIC = 4;
export const TILE = 3;

export const MEDIA_TYPE = {
  VIDEO: {
    label: 'Video',
    value: 'VIDEO',
  },
  CAROUSEL_ALBUM: {
    label: 'Carousel album',
    value: 'CAROUSEL_ALBUM',
  },
};

export const SOURCE_TYPE = {
  BUSINESS_ACCOUNT: { name: 'My Business Account', value: 'BUSINESS_ACCOUNT' },
  PERSONAL_ACCOUNT: { name: 'My Personal Account', value: 'PERSONAL_ACCOUNT' },
};

export const POST_STYLE = {
  HOVER: { label: 'Hover', value: 'HOVER' },
  SIMPLE: { label: 'Simple', value: 'SIMPLE' },
};

export const languageOptions = [
  { value: 'English', label: 'English' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Spanish', label: 'Spanish' },
];

export const POPUP_FEATURES = `height=700,width=600,left=500,top=200,scrollbars=yes,menubar=no`;

export const LANG_OPTIONS = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
  { code: 'de', name: 'Deutsch' },
  { code: 'es', name: 'Español' },
  { code: 'fi', name: 'Suomi' },
  { code: 'it', name: 'Italiano' },
  { code: 'ja', name: '日本語' },
  { code: 'ko', name: '한국어' },
  { code: 'nl', name: 'Nederlands' },
  { code: 'pl', name: 'Polski' },
  { code: 'pt', name: 'Português' },
  { code: 'ru', name: 'Русский' },
  { code: 'sv', name: 'Svenska' },
  { code: 'tr', name: 'Türkçe' },
  { code: 'zh-CN', name: '中文 (Simplified)' },
  { code: 'zh-TW', name: '中文 (Traditional)' },
  { code: 'vi', name: 'Tiếng Việt' },
  { code: 'hi', name: 'हिन्दी' },
  { code: 'ar', name: 'عربي' },
  { code: 'da', name: 'Dansk' },
  { code: 'cs', name: 'Čeština' },
  { code: 'hu', name: 'Magyar' },
  { code: 'hr', name: 'Hrvatski' },
  { code: 'ro', name: 'Română' },
  { code: 'lt', name: 'Lietuvių' },
  { code: 'az', name: 'Azərbaycanca' },
  { code: 'uk', name: 'Українська' },
  { code: 'el', name: 'Ελληνικά' },
];

export const SECONDS = 0;
export const MINUTES = 2;

export const PLANS = {
  FREE_PLAN: {
    key: 'FREE_PLAN',
    label: 'Free Plan',
  },

  BASIC_LIFE_TIME_DEAL: {
    key: 'BASIC_LIFE_TIME_DEAL',
    label: 'Basic Plan [LTD]',
    original: 39,
  },

  PRO_LIFE_TIME_DEAL: {
    key: 'PRO_LIFE_TIME_DEAL',
    label: 'Pro Plan [LTD]',
    original: 49,
  },

  AGENCY_LIFE_TIME_DEAL: {
    key: 'AGENCY_LIFE_TIME_DEAL',
    label: 'Agency plan [LTD]',
    original: 199,
  },
};

export const LIMIT_KEYS = {
  WIDGET_CREATION_LIMIT: {
    key: 'allowWidgetCreation',
    label: 'Widget creation limit',
  },
  WEBSITE_ADD_LIMIT: {
    key: 'allowWebsiteAddLimit',
    label: 'Website add limit',
  },
  NUMBER_OF_VIEWS: { key: 'viewLimitReached', label: 'Number of views' },
  NUMBER_OF_COMMENTS_LIKES: {
    key: 'allowCommentLikeCount',
    label: 'Number of comments and likes',
  },
  POP_UP_MODE: { key: 'allowPostPopup', label: 'Pop up mode' },
  HOVER_EFFECT: { key: 'allowPostHoverEffect', label: 'Hover effect' },
  SOCIAL_SHARE_BUTTON: {
    key: 'allowSharingOfPost',
    label: 'Social share button',
  },
  FEED_HASHTAG_MODERATE: {
    key: 'allowHashtagAndMentions',
    label: 'Feed hashtag moderate',
  },
  FEED_MENTIONS_MODERATE: {
    key: 'allowHashtagAndMentions',
    label: 'Feed mentions moderate',
  },
  ACCOUNT_INFO_WITH_FOLLOW_BUTTON: {
    key: 'allowFollowButton',
    label: 'Account info with follow button',
  },
  ADJUSTABLE_FEED_WIDGET_SIZE: {
    key: 'allowAdjustableWidgetWidth',
    label: 'Adjustable feed widget size',
  },
  CAROUSEL_AND_REEL_SUPPORT: {
    key: 'allowCarouselReelSupport',
    label: 'Adjustable feed widget size',
  },
  CTA_BUTTON: { key: 'allowCTA', label: 'Call to action button' },
  FULLY_RESPONSIVE_ALL_DEVICES: {
    key: 'allowResponsive',
    label: 'Fully responsive all devices',
  },
  IG_BUSINESS_FEED: { key: 'allowIGBusinessFeed', label: 'IG business feed' },
  IG_PERSONAL_FEED: { key: 'allowIGPersonalFeed', label: 'IG personal feed ' },
  MULTI_LAYOUT_CHOICES: {
    key: 'allowMultipleLayout',
    label: 'Multi layout choices',
  },
  UPDATE_TIME: { key: 'updateTime', label: 'Update time' },
  WATERMARK: { key: 'allowWaterMark', label: 'Watermark' },
  SUPPORT: { key: 'allowSupport', label: 'Support' },
  CUSTOM_CSS: { key: 'allowCustomCss', label: 'Custom CSS' },
  NEW_LAYOUT: { key: 'allowNewLayout', label: 'New Layout' },
  CONTENT_MODERATION: { key: 'allowPostModeration', label: 'Post Moderation' },
};

export const notAllowedKeys = ['likeCount', 'commentCount', 'share'];

export const BILLING_TYPE = {
  MONTHLY: {
    key: 'MONTHLY',
    label: 'Monthly',
  },
  YEARLY: {
    key: 'YEARLY',
    label: 'Yearly',
  },
  ONE_TIME: {
    key: 'ONE_TIME',
    label: 'One time',
  },
};

export const SUPPORT_MAIL_ADDRESS = 'support@instaplug.app';

export const GA_EVENT_NAMES = {
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  CLICK_ON_CREATE_WIDGET: 'click_on_create_widget',
  CREATE_WIDGET_SUCCESS: 'create_widget_success',
  DELETE_WIDGET: 'delete_widget',
  ADD_WIDGET_TO_WEBSITE: 'add_widget_to_website',
  COPY_CODE_BUTTON: 'copy_code_button',
  PUBLISH_WIDGET: 'publish_widget',
  CLOSE_WIDGET: 'close_widget',
  CONNECT_FACEBOOK_BUSINESS_ACCOUNT: 'connect_facebook_business_account',
  CONNECT_INSTAGRAM_PERSONAL_ACCOUNT: 'connect_instagram_personal_account',
  SHOW_POST_FILTER: 'show_post_filter',
  HIDE_POST_FILTER: 'hide_post_filter',
  BACK_BUTTON_FILTER: 'back_button_filter',
  BACK_BUTTON_COLUMN_ROW: 'back_button_column_row',
  BACK_BUTTON_HEADER: 'back_button_header',
  BACK_BUTTON_SLIDER_SETTINGS: 'back_button_slider_settings',
  BACK_BUTTON_FEED_TITLE: 'back_button_feed_title',
  BACK_BUTTON_POST_STYLE: 'back_button_post_style',
  BACK_BUTTON_POPUP: 'back_button_popup',
  BACK_BUTTON_CTA_ACTION_BUTTON: 'back_button_cta_action_button',
  BACK_BUTTON_CTA_EDIT_BUTTON: 'back_button_cta_edit_button',
  SLIDER_LAYOUT: 'slider_layout',
  GRID_LAYOUT: 'grid_layout',
  MASONRY_LAYOUT: 'masonry_layout',
  GALLERY_LAYOUT: 'gallery_layout',
  COL_ROW_SETTINGS: 'column_row_settings',
  HEADER_SETTINGS: 'header_settings',
  SLIDER_SETTINGS: 'slider_settings',
  FEED_TITLE_SETTINGS: 'feed_title_settings',
  POST_STYLE_SETTINGS: 'post_style_settings',
  HOVER_POST_STYLE: 'hover_post_style',
  SIMPLE_POST_STYLE: 'simple_post_style',
  POST_POPUP_ELEMENTS: 'post_popup_elements',
  CALL_TO_ACTION: 'call_to_action',
  CALL_TO_ACTION_DONE: 'call_to_action_done',
  LANGUAGE_SETTINGS: 'language_settings',
  RENAME_WIDGET: 'rename_widget',
  USER_PROFILE: 'user_profile',
  LOGOUT_APP: 'logout_app',
  SHOW_HEADER_PROFILE_PICTURE: 'show_header_profile_picture',
  SHOW_HEADER_FULL_NAME: 'show_header_full_name',
  SHOW_HEADER_USERNAME: 'show_header_username',
  SHOW_HEADER_VERIFIED_BADGE: 'show_header_verified_badge',
  SHOW_HEADER_POST_COUNT: 'show_header_post_count',
  SHOW_HEADER_FOLLOWER_COUNT: 'show_header_follower_count',
  SHOW_HEADER_FOLLOWING_COUNT: 'show_header_following_count',
  SHOW_HEADER_FOLLOW_BUTTON: 'show_header_follow_button',
  SHOW_HOVER_POST_LIKE_COUNT: 'show_hover_post_like_count',
  SHOW_HOVER_POST_COMMENTS_COUNT: 'show_hover_post_comments_count',
  SHOW_HOVER_POST_TEXT: 'show_hover_post_text',
  SHOW_SIMPLE_POST_USER: 'show_simple_post_user',
  SHOW_SIMPLE_POST_DATE: 'show_simple_post_date',
  SHOW_SIMPLE_POST_INSTAGRAM_LINK: 'show_simple_post_instagram_link',
  SHOW_SIMPLE_POST_SHARE: 'show_simple_post_share',
  SHOW_SIMPLE_POST_LIKE_COUNT: 'show_simple_post_like_count',
  SHOW_SIMPLE_POST_COMMENTS_COUNT: 'show_simple_post_comments_count',
  SHOW_SIMPLE_POST_TEXT: 'show_simple_post_text',
  SHOW_POPUP_ELEMENT_USER: 'show_popup_element_user',
  SHOW_POPUP_ELEMENT_LOCATION: 'show_popup_element_location',
  SHOW_POPUP_ELEMENT_FOLLOW_BUTTON: 'show_popup_element_follow_button',
  SHOW_POPUP_ELEMENT_INSTAGRAM_LINK: 'show_popup_element_instagram_link',
  SHOW_POPUP_ELEMENT_LIKE_COUNT: 'show_popup_element_like_count',
  SHOW_POPUP_ELEMENT_SHARE: 'show_popup_element_share',
  SHOW_POPUP_ELEMENT_TEXT: 'show_popup_element_text',
  SHOW_POPUP_ELEMENT_DATE: 'show_popup_element_date',
  SHOW_POPUP_ELEMENT_COMMENTS: 'show_popup_element_comments',
  CLICK_ON_DASHBOARD_VIEW_PLANS: 'click_on_dashboard_view_plans',
  CLICK_ON_WIDGET_VIEW_PLANS: 'click_on_widget_view_plans',
  CLICK_ON_BUY_NOW_BUTTON: 'click_on_buy_now_button',
  INSTAGRAM_POST_URL_CTA: 'instagram_post_url_cta',
  BUTTON_URL_CTA: 'button_url_cta',
  BUTTON_LABEL_CTA: 'button_label_cta',
  UPGRADED_TO_PRO: 'upgraded_to_pro',
  UPGRADED_TO_BASIC: 'upgraded_to_basic',
  UPGRADED_TO_AGENCY: 'upgraded_to_agency',
  CLICK_ON_UPGRADE: 'click_on_upgrade',
  ONBOARDING_START: 'onboarding_start',
  ONBOARDING_END: 'onboarding_end',
  ONBOARDING_SKIP: 'onboarding_skip',
};

export const GA_EVENT_PARAMETERS = {
  LOGIN_TYPE: 'login_type',
  USER_ID: 'userId',
  WIDGET_ID: 'widget_id',
  LAYOUT_TYPE: 'layout_type',
  LANGUAGE_CODE: 'language_code',
  PLAN_NAME: 'plan_name',
};

export const ERROR_MESSAGE_TYPE = {
  WIDGET_VIEW_LIMIT_REACHED: 'WIDGET_VIEW_LIMIT_REACHED',
  WIDGET_ADD_LIMIT_REACHED: 'WIDGET_ADD_LIMIT_REACHED',
  PLEASE_CONTACT_SUPPORT: 'PLEASE_CONTACT_SUPPORT',
};

export const PAYMENT_CHECKOUT = {
  CHECKOUT_CLOSED: 'checkout.closed',
  CHECKOUT_COMPLETED: 'checkout.completed',
  STATUS: {
    COMPLETED: 'completed',
    DRAFT: 'draft',
    PENDING: 'PENDING',
    READY: 'ready',
  },
  KEY: 'LIFE_TIME_DEAL',
};

export const ONBOARD_MODAL = [
  {
    key: 'sign-up',
    image: signup,
    title: 'Sign Up and Create Your Instaplug Account',
    description:
      'You can create a new account using your email address, or sign in using your Facebook or Google account for quicker access.',
  },
  {
    key: 'create-widget',
    image: createWidget,
    title: 'Create and Customize Your Widget',
    description: 'On your dashboard, click “Create New Widget."',
  },
  {
    key: 'connect-instagram',
    image: instagramConnect,
    title: 'Connect Your Instagram Account',
    description:
      'Click on the widget and select "Connect Instagram." You will be prompted to log into your Instagram account.',
  },
  {
    key: 'customize-widget',
    image: customizeWidget,
    title: 'Customize Your Widget',
    description: `Use the customization for the visual appearance. Tailor the widget to match your website's branding and design.`,
  },
  {
    key: 'publish-widget',
    image: publishWidget,
    title: 'Publish Your Widget',
    description:
      'Check the appearance of your widget using the preview option to ensure it looks perfect.',
  },
  {
    key: 'copy-code',
    image: copyCode,
    title: ' Copy the Embed Code ',
    description:
      'A unique code will be provided; copy this code to use on your website.',
  },
  {
    key: 'embed-widget',
    image: embedCode,
    title: 'Embed Your Widget on Your Website',
    description:
      'Go to the page or section where you want to embed your Instagram feed.',
  },
];

export const FONT_FAMILIES_LIST = [
  'Arial',
  'Caveat',
  'Comfortaa',
  'Courier new',
  'Eb garamond',
  'Lexend',
  'Lobster',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Nunito',
  'Oswald',
  'Pacifico',
  'Playfair display',
  'Roboto',
  'Roboto mono',
  'Roboto serif',
  'Spectral',
  'Times new roman',
];

export const masonryResponsiveValues = {
  350: 1,
  750: 2,
};

export const MAX_POST_LIMIT = 100;

export const PLAN_FEATURE_GROUP_LABEL = {
  'Personal account feeds': 'Instagram Feed Creation:',
  'Carousel and Reels': 'Post Display:',
  'Enhance the conversion rate of your business by adding Call to Action button with a link':
    'Custom CTA',
  'Moderate your feed with filters [hashtag]': 'Moderation and Interaction:',
  'Account info with follow button': 'User Engagement (Redirection):',
};
