import { Button } from 'antd';
import React from 'react';

const ScriptGuidelines = ({ copyScript }) => (
  <>
    <center>
      <h1>
        <u>Guidelines to Improve Performance of Website</u>
      </h1>
    </center>
    <section>
      <p>
        Improving website performance entails optimizing various aspects of the
        site's structure and code. Below are technical guidelines tailored to
        different platforms like Webflow, React, and Next.js.
      </p>
    </section>
    <main>
      <section id="webflow">
        <h2>Webflow:</h2>
        <ul>
          <p>
            <strong>Script Placement:</strong> Ensure that JavaScript scripts
            are placed at the bottom of the body tag within your HTML code.
            Placing scripts at the bottom allows the HTML content to load first,
            enhancing the perceived loading speed for users.
          </p>
          <p>
            <strong>Defer / Async Attributes:</strong> Utilize the defer or
            async attributes within your script tags. These attributes help in
            asynchronous loading of scripts, allowing other resources to
            continue loading concurrently without waiting for the script to
            execute.
          </p>
        </ul>
        <ul>
          <strong>
            Please follow these steps to properly insert your script into the
            code:
          </strong>

          <ol>
            <li>Open your HTML code.</li>
            <li>Find the body tag.</li>
            <li>
              Place the script at the bottom of the body tag.{' '}
              <Button type="primary" size="small" onClick={copyScript}>
                Copy script
              </Button>
            </li>
            <li>
              Use async or defer in your script, whichever provides the best
              performance.
            </li>
          </ol>
        </ul>
      </section>

      <section id="react">
        <h2>React app:</h2>
        <ul>
          <p>
            <strong>Script Placement:</strong> Insert script tags at the bottom
            of the body tag in your index.html file.
          </p>
          <p>
            <strong>Defer & Async:</strong> Similar to Webflow, utilize the
            defer and async attributes within your script tags to facilitate
            asynchronous loading of scripts, improving overall page load
            performance.
          </p>
        </ul>
        <ul>
          <strong>
            Please follow these steps to properly insert your script into the
            code:
          </strong>

          <ol>
            <li>Open your index.html file.</li>
            <li>
              Place the script at the bottom of the body tag.{' '}
              <Button type="primary" size="small" onClick={copyScript}>
                Copy script
              </Button>
            </li>
            <li>Use async & defer in your script.</li>
          </ol>
        </ul>
      </section>

      <section id="next">
        <h2>Next app:</h2>
        <ul>
          <p>
            <strong>Lazy Loading:</strong> Implement lazy loading techniques to
            defer the loading of non-essential scripts until they are needed. In
            the case of Next.js, you can execute scripts dynamically based on
            user interactions, such as scrolling.
          </p>
        </ul>

        <ul>
          <strong>
            Please follow these steps to properly insert your script into the
            code:
          </strong>

          <ol>
            <li>
              Create a div component which is responsible to trigger the script
              component. pass an id according to which script component will
              load.
            </li>
            <li>
              Create a script component that will be responsible for loading and
              running the Instaplug widget.{' '}
              <Button type="primary" size="small" onClick={copyScript}>
                Copy script
              </Button>
            </li>
            <li>
              Implement the logic to load the script component when it gets the
              id from the div component.
            </li>
            <li>
              Place the div component where you want to show the Instaplug
              widget in your page.
            </li>
          </ol>
        </ul>
      </section>
    </main>
  </>
);

export default ScriptGuidelines;
