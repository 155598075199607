import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgetPassword = () => {
  const history = useHistory();
  const { Title } = Typography;
  const [ResetRequest, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted() {
      history?.push(ROUTES?.LOGIN);
    },
    onError() {}, // Always write this method for error handling in all mutation.
  });

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
      };
      await ResetRequest({
        variables: { ...formValues },
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="auth-bg d-flex">
      <div className="login-wrap align-center justify-start d-flex">
        <Spin spinning={false} wrapperClassName="full-width">
          <Title>
            <center>
              <img src="/logo.png" alt="svg logo" className="auth-logo" />
            </center>
          </Title>
          <Card className="full-width">
            <Title level={3}>
              <center>Reset Password</center>
            </Title>
            <Form layout="vertical" onFinish={onFinish} size="large">
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button
                  type="primary"
                  disabled={loading}
                  className="full-width"
                  htmlType="submit"
                >
                  Reset My Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <div>
            <center>
              <p>
                Or go back to
                <Button
                  type="link"
                  onClick={() => {
                    history?.push(ROUTES?.LOGIN);
                  }}
                  size="small"
                >
                  <u>Login</u>
                </Button>
                page
              </p>
            </center>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default ForgetPassword;
