import { gql } from '@apollo/client';

export const REGISTER_CUSTOMER = gql`
  mutation RegisterCustomer {
    registerCustomer {
      message
    }
  }
`;

export const SUBSCRIBE_TO_PLAN_PADDLE = gql`
  mutation SubscribePaddle($subscriptionPlanId: ID!) {
    subscribePaddle(subscriptionPlanId: $subscriptionPlanId) {
      id
      status
      customerId
      customData
    }
  }
`;

export const UPGRADE_SUBSCRIPTION = gql`
  mutation UpgradeSubscription($input: SubscriptionUniqueId!) {
    upgradeSubscription(input: $input) {
      id
      metadata
    }
  }
`;

export const UNSUBSCRIBE_PLAN_PADDLE = gql`
  mutation CancelPaddleSubscription {
    cancelPaddleSubscription {
      message
    }
  }
`;
