import { RightOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { SUPPORT_MAIL_ADDRESS } from '../../../common/constants';
import '../feature-access.less';

function RemoveSubscriptionModal({ open, setOpen, unsubscribe }) {
  const {
    state: { mailSubject },
  } = useContext(AppContext);
  return (
    <Modal
      width={450}
      wrapClassName="feature-access-modal"
      footer={false}
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      // eslint-disable-next-line no-undef
      getContainer={() => document?.getElementById('root')}
    >
      <div className="feature-wrapper for-unsubscribe d-flex flex-vertical justify-between p-10 full-height ">
        <div className="feature-modal-header">
          Are you sure you want to cancel subscription?
        </div>
        <div className="feature-modal-info">
          <ul className="pl-18">
            <li> User paid plan will be converted to Free Plan.</li>
            <li> You will unable to use paid features.</li>
            <li>
              Your widgets will be removed except the first widget you have
              created.
            </li>
            <li>
              Removed widget that are added in websites will no longer
              available.
            </li>
          </ul>

          <div className="mt-8">
            Please contact us for more Information
            <a
              href={`mailto:${SUPPORT_MAIL_ADDRESS}?subject=Support for:  ${mailSubject}`}
              target="_blank"
              rel="noreferrer"
              className="text-center"
            >
              <span className="font-bold"> {SUPPORT_MAIL_ADDRESS}</span>
            </a>
          </div>
        </div>
        <div className="d-flex justify-end">
          <Button
            className="cancel-subscription upgrade-btn d-flex align-center"
            type="default"
            onClick={unsubscribe}
            icon={<RightOutlined />}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default RemoveSubscriptionModal;
