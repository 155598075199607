import { Card, Checkbox, Col, Row, Switch } from 'antd';
import { map } from 'lodash';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  HEADERS_TITLE,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';

function Headers() {
  const {
    state: { showHeaders, headerController, previewStyle },
    handleHeaderController,
    handleShowHeader,
  } = useContext(AppContext);
  const { id } = useParams();
  const toggleHeader = (value) => {
    handleHeaderController(
      value ? map(HEADERS_TITLE, (item) => item?.value) : [],
    );
    handleShowHeader(value);
  };

  const handleUserHeader = (valueArray) => {
    handleHeaderController(valueArray);
  };

  const handleHeaderGaEvents = (value) => {
    const parameters = {
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    };
    switch (value) {
      case HEADERS_TITLE.profilePicture.value:
        GaEvent(GA_EVENT_NAMES.SHOW_HEADER_PROFILE_PICTURE, parameters);
        break;
      case HEADERS_TITLE.fullName.value:
        GaEvent(GA_EVENT_NAMES.SHOW_HEADER_FULL_NAME, parameters);
        break;
      case HEADERS_TITLE.userName.value:
        GaEvent(GA_EVENT_NAMES.SHOW_HEADER_USERNAME, parameters);
        break;
      case HEADERS_TITLE.verifiedBadge.value:
        GaEvent(GA_EVENT_NAMES.SHOW_HEADER_VERIFIED_BADGE, parameters);
        break;
      case HEADERS_TITLE.postsCount.value:
        GaEvent(GA_EVENT_NAMES.SHOW_HEADER_POST_COUNT, parameters);
        break;
      case HEADERS_TITLE.followersCount.value:
        GaEvent(GA_EVENT_NAMES.SHOW_HEADER_FOLLOWER_COUNT, parameters);
        break;
      case HEADERS_TITLE.followingCount.value:
        GaEvent(GA_EVENT_NAMES.SHOW_HEADER_FOLLOWING_COUNT, parameters);
        break;
      case HEADERS_TITLE.followButton.value:
        GaEvent(GA_EVENT_NAMES.SHOW_HEADER_FOLLOW_BUTTON, parameters);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Card
        className="full-width"
        size="small"
        title={
          <>
            <Row gutter={80} justify="space-between">
              <Col span={14} className="font-m">
                Show headers
              </Col>
              <Col>
                <Switch checked={showHeaders} onChange={toggleHeader} />
              </Col>
            </Row>
          </>
        }
      >
        {showHeaders && (
          <>
            <h4 className="font-m mt-6 mb-6">Header elements</h4>
            <Row gutter={[16, 16]}>
              <Checkbox.Group
                className="header-checkbox-group mt-8"
                onChange={handleUserHeader}
                value={headerController}
              >
                {map(HEADERS_TITLE, (item) => (
                  <Checkbox
                    disabled={item?.disabled}
                    className="mt-6"
                    value={item?.value}
                    onClick={() => handleHeaderGaEvents(item?.value)}
                  >
                    {item?.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Row>
          </>
        )}
      </Card>
    </div>
  );
}

export default Headers;
