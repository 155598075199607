import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { formValidatorRules } from '../../../common/utils';
import { EDIT_PROFILE } from '../../auth/graphql/Mutations';

const { required, name } = formValidatorRules;

function UserInfoRequiredModal({
  open = false,
  setOpen,
  planId,
  buyPlan,
  setWaitForSuccess,
}) {
  const [form] = Form?.useForm();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [editProfile, { loading }] = useMutation(EDIT_PROFILE, {
    onCompleted() {
      setOpen(false);
    },
    onError() {},
  });

  const onFinish = async (values) => {
    try {
      const response = await editProfile({
        variables: {
          details: values,
        },
      });
      if (response && planId?.length > 0) {
        buyPlan(planId);
      }
    } catch (error) {
      return false;
    }
  };
  const { firstName, lastName } = currentUser;
  const initialValues = {
    firstName,
    lastName,
  };

  return (
    <Modal
      open={open}
      onOk={form?.submit}
      onCancel={() => {
        setWaitForSuccess(false);
        setOpen(false);
      }}
      title="Required for payment"
      confirmLoading={loading}
      width={400}
      wrapClassName="user-info-required-modal"
      centered
      footer={false}
      // eslint-disable-next-line no-undef
      getContainer={() => document?.getElementById('root')}
    >
      <Form
        form={form}
        className="full-width"
        layout="vertical"
        onFinish={onFinish}
        size="middle"
        initialValues={initialValues}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ ...required, message: 'Please enter First Name' }, name]}
          className="mb-12"
        >
          <Input placeholder="Enter First Name" allowClear />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ ...required, message: 'Please enter Last Name' }, name]}
          className="mb-12"
        >
          <Input placeholder="Enter Last Name" allowClear />
        </Form.Item>
        <Form.Item>
          <div className="d-flex col-gap-8 justify-end full-width">
            <Button
              className="all-btn"
              htmlType="reset"
              onClick={() => {
                setWaitForSuccess(false);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="all-btn"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Continue
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UserInfoRequiredModal;
