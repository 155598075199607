import {
  DeleteOutlined,
  GlobalOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { includes } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import UserProfile from '../../app/components/header/UserProfile';
import { PremiumIcon } from '../../assets/svg';
import {
  BILLING_TYPE,
  GA_EVENT_NAMES,
  LIMIT,
  OFFSET,
  ONBOARD_MODAL,
  PLANS,
  ROUTES,
  defaultDateFormat,
} from '../../common/constants';
import { GaEvent, getPlanColor } from '../../common/utils';
import CommonLogo from '../../components/CommonLogo';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import { UNSUBSCRIBE_PLAN_PADDLE } from '../FeatureAccess/Graphql/Mutations';
import RemoveSubscriptionModal from '../FeatureAccess/access-prompts/RemoveSubscriptionModal';
import TotalWidgetAccess from '../FeatureAccess/access-prompts/TotalWidgetAccess';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';
import USER_PROFILE from '../auth/graphql/Queries';
import ScriptGuidelines from './ScriptGuidelines';
import { CREATE_WIDGET, DELETE_WIDGET } from './graphql/Mutations';
import WIDGET_LISTING from './graphql/Queries';

const { Title } = Typography;

const getCurrent = (offset, limit) => offset / limit + 1;

function Dashboard() {
  const {
    setCurrentUser,
    setMailSubject,
    setOnboardModal,
    state: { currentUser, onboardModal },
  } = useContext(AppContext);
  const history = useHistory();
  const [widgetList, setWidgetList] = useState([]);
  const [open, setOpen] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState(null);
  const [copy, setCopy] = useState(false);
  const [limit, setLimit] = useState(LIMIT);
  const [offset, setOffset] = useState(OFFSET);
  const [total, setTotal] = useState();
  const [deleteId, setDeleteId] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [widgetLimitModal, setWidgetLimitModal] = useState(false);
  const [openUnsubscribeModal, setOpenUnsubscribeModal] = useState(false);
  const { limit: widgetLimit, usage } = currentUser?.userWidgetQuota || {};
  const [isLastSlide, setIsLastSlide] = useState(false);
  const carouselRef = useRef(null);

  const {
    widgetAccessConfig: hasAccessTo,
    widgetLimitAndUsage,
  } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const [getUser] = useLazyQuery(USER_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setCurrentUser(res?.userProfile);
    },
    onError() {},
  });

  const initialWidgetsFilter = {
    pagination: {
      limit,
      offset,
    },
  };

  const setTypeOfModalToOpen = (type) => {
    setTypeOfModal(type);
    setOpen(true);
  };

  const openCreateWidgetModal = () => {
    GaEvent(GA_EVENT_NAMES.CLICK_ON_CREATE_WIDGET);
    setMailSubject('Instplug widget limit reached');
    if (hasAccessTo?.allowWidgetCreation) {
      setWidgetLimitModal(true);
      return;
    }
    setTypeOfModalToOpen('widgetCreation');
  };

  const [getWidgets, { loading: fetchWidgets }] = useLazyQuery(WIDGET_LISTING, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setWidgetList(res?.getWidgets?.data);
      setTotal(res?.getWidgets?.count);
    },
  });

  const [createWidgets, { loading: createWidgetLoading }] = useMutation(
    CREATE_WIDGET,
    {
      async onCompleted(res) {
        const userResponse = await getUser();
        if (userResponse) {
          GaEvent(GA_EVENT_NAMES?.CREATE_WIDGET_SUCCESS);
          history?.push(`${ROUTES?.WIDGET}/${res?.createWidget?.data?.id}`);
        }
      },
      onError() {
        setOpen(true);
      },
    },
  );

  const [removeWidget, { loading: deleteWidgetLoading }] = useMutation(
    DELETE_WIDGET,
    {
      onCompleted() {
        GaEvent(GA_EVENT_NAMES?.DELETE_WIDGET);
        getWidgets({
          variables: initialWidgetsFilter,
        });
        setOpen(false);
        getUser();
      },
    },
  );

  const [unsubscribe] = useMutation(UNSUBSCRIBE_PLAN_PADDLE, {
    onCompleted() {
      setOpenUnsubscribeModal(false);
      getUser();
      getWidgets({
        variables: initialWidgetsFilter,
      });
    },
    onError() {},
  });

  useEffect(() => {
    getWidgets({
      variables: {
        pagination: {
          limit,
          offset,
        },
      },
    });
  }, [offset, limit]);

  const handleFinish = (value) => {
    createWidgets({
      variables: {
        data: {
          name: value?.name,
        },
      },
    });
  };

  const handleEditWidget = (widget) => {
    const id = widget?.id;
    const handleRow = () => {
      history?.push(`${ROUTES?.WIDGET}/${id}`);
    };
    return {
      onClick: handleRow,
    };
  };
  const getDeleteWidgetId = (id) => {
    setDeleteId(id);
  };
  const handleDeleteWidget = () => {
    removeWidget({
      variables: {
        where: {
          id: deleteId,
        },
      },
    });
  };

  const handleInstallation = (e, record) => {
    e?.stopPropagation();
    setTypeOfModalToOpen('generateEmbeddedCode');
    setOpen(true);
    setSelectedId(record?.id);
    GaEvent(GA_EVENT_NAMES.ADD_WIDGET_TO_WEBSITE);
  };

  const handleModalState = () => {
    setOpen(false);
    setCopy(false);
  };

  const handleOkProp = {
    className: 'all-btn',
  };
  const handleCancelProp = {
    className: 'all-btn',
  };

  const widgetColumns = [
    {
      title: 'Name',
      width: 200,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Views',
      width: 200,
      dataIndex: 'views',
      key: 'views',
      render(record) {
        return (
          <span>
            {record} / {widgetLimitAndUsage?.NUMBER_OF_VIEWS?.limit}
          </span>
        );
      },
    },
    {
      title: 'Installation',
      width: 250,
      dataIndex: 'installation',
      key: 'installation',
      render: (_, record) => (
        <>
          <Button
            size="middle"
            key={record?.id}
            className="full-width all-btn d-flex align-center justify-center"
            onClick={(e) => handleInstallation(e, record)}
            icon={<GlobalOutlined />}
          >
            Add to website
          </Button>
        </>
      ),
    },
    {
      title: 'Last Modified',
      width: 200,
      dataIndex: 'updatedAt',
      key: 'Last Modified',
      render: (_, record) =>
        moment(record?.updatedAt)?.format(defaultDateFormat),
    },
    {
      title: 'Publish status',
      dataIndex: 'published',
      key: 'published',

      render: (columnData) => (
        <Tag color={columnData ? 'green' : 'red'}>
          {columnData ? 'Published' : 'Un-published'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (_, record) => (
        <>
          <Tooltip title="Delete widget">
            <DeleteOutlined
              onClick={(e) => {
                e?.stopPropagation();
                getDeleteWidgetId(record?.id);
                setTypeOfModalToOpen('deleteWidget');
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleTableChange = (
    { current, pageSize },
    filterType,
    _,
    { action },
  ) => {
    switch (action) {
      case 'paginate': {
        setOffset(current * pageSize - pageSize);
        setLimit(pageSize);
        break;
      }

      default:
        break;
    }
  };
  const widgetNotCreated = widgetList?.length === 0;
  const copyCode = () => {
    // Select the code inside the <code> element
    // eslint-disable-next-line no-undef
    const codeElement = document?.getElementById('scriptCode');
    // eslint-disable-next-line no-undef
    const range = document?.createRange();
    range?.selectNode(codeElement);
    // eslint-disable-next-line no-undef
    window?.getSelection()?.removeAllRanges();
    // eslint-disable-next-line no-undef
    window?.getSelection()?.addRange(range);

    // Copy the selected code
    // eslint-disable-next-line no-undef
    document?.execCommand('copy');
    setCopy(true);
    message.success('Script copied!');
    // Deselect the text
    // eslint-disable-next-line no-undef
    window?.getSelection()?.removeAllRanges();
    GaEvent(GA_EVENT_NAMES.COPY_CODE_BUTTON);
  };

  const renderAppConfig = {
    containerId: selectedId,
    // eslint-disable-next-line no-undef
    domain: window?.location?.href,
    widgetClass: '',
    fontFamily: '',
    color: '',
    colorLink: '',
    colorLinkActive: '',
    colorLinkHover: '',
  };

  const resetCarousel = () => {
    if (carouselRef.current) {
      carouselRef.current.goTo(0, false); // goTo method is used to reset to the first slide
    }
  };

  const handleDone = () => {
    GaEvent(
      GA_EVENT_NAMES.ONBOARDING_END,
    ); /* GE event to track onboarding end */
    resetCarousel();
    setOnboardModal(false);
    setIsLastSlide(false);
  };

  const nextSlide = () => {
    if (!isLastSlide && carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const onChange = (currentSlideAlt) => {
    if (currentSlideAlt === 1) {
      GaEvent(
        GA_EVENT_NAMES.ONBOARDING_START,
      ); /* GA event to track onboarding start */
    }
    if (currentSlideAlt === ONBOARD_MODAL?.length - 1) {
      setIsLastSlide(true);
    }
  };

  const returnPlanColor = useMemo(() => getPlanColor(currentUser), [
    currentUser,
  ]);

  if (fetchWidgets) {
    return <LoaderComponent setHeight={100} />;
  }

  return (
    <div className="align-center justify-start d-flex">
      {/* onboarding modal */}

      <Modal
        className="overflow-auto"
        open={onboardModal}
        onCancel={() => {
          setOnboardModal(false);
        }}
        width={1000}
        closable={false}
        maskClosable={false}
        footer={
          <>
            <Button
              type="link"
              onClick={() => {
                GaEvent(GA_EVENT_NAMES.ONBOARDING_SKIP); // GA event to track onboarding skip
                setOnboardModal(false);
                resetCarousel();
              }}
            >
              <u>Skip</u>
            </Button>
            <Button
              type="primary"
              className="btn-rounded"
              onClick={isLastSlide ? handleDone : nextSlide}
            >
              {isLastSlide ? 'Done' : 'Next'}
            </Button>
          </>
        }
      >
        <div>
          <Carousel
            afterChange={onChange}
            infinite={false}
            ref={carouselRef}
            dots={false}
          >
            {ONBOARD_MODAL?.map((item) => (
              <div key={item?.key}>
                <center>
                  <div className="onboard-modal">
                    <Image
                      preview={false}
                      width="100%"
                      src={item?.image}
                      alt="image-placeholder"
                    />
                  </div>
                </center>
                <div className="onboard-description">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </Modal>

      <Portal portalId="header-title">
        <div className="full-height">
          <CommonLogo src="/logo-light.png" classes="full-logo" />
        </div>
      </Portal>
      <Portal portalId="profile">
        <UserProfile />
      </Portal>

      <Portal portalId="header-title">
        <Button
          className="all-btn upgrade-btn mr-10"
          onClick={() => {
            GaEvent(GA_EVENT_NAMES.CLICK_ON_DASHBOARD_VIEW_PLANS);
            history?.push(ROUTES?.MANAGE_PRICING);
          }}
        >
          View plans
        </Button>
      </Portal>

      {widgetLimitModal && (
        <TotalWidgetAccess
          open={widgetLimitModal}
          setOpen={setWidgetLimitModal}
        />
      )}

      {openUnsubscribeModal && (
        <RemoveSubscriptionModal
          open={openUnsubscribeModal}
          setOpen={setOpenUnsubscribeModal}
          unsubscribe={unsubscribe}
        />
      )}

      {open && (
        <Modal
          width={
            typeOfModal === 'widgetCreation' || typeOfModal === 'deleteWidget'
              ? 400
              : 800
          }
          title={
            typeOfModal === 'deleteWidget' ? (
              <h1 className="font-2xl m-0">Delete widget</h1>
            ) : (
              <Title level={5}>
                {typeOfModal === 'generateEmbeddedCode'
                  ? 'Add widget to your website'
                  : 'Enter widget name'}
              </Title>
            )
          }
          open={open}
          onOk={handleModalState}
          footer={null}
          onCancel={handleModalState}
          okButtonProps={handleOkProp}
          cancelButtonProps={handleCancelProp}
          confirmLoading={createWidgetLoading}
          closable={typeOfModal === 'generateEmbeddedCode'}
          centered
        >
          {typeOfModal === 'widgetCreation' && (
            <>
              <Form onFinish={handleFinish}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please enter widget name',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter widget name"
                    allowClear
                    disabled={createWidgetLoading}
                  />
                </Form.Item>
                <Form.Item>
                  <Row className="justify-end" gutter={8}>
                    <Col span={12}>
                      <Button
                        type="primary"
                        danger
                        onClick={() => setOpen(false)}
                        style={{ width: '100%', borderRadius: '6px' }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="full-width all-btn"
                        loading={createWidgetLoading}
                        style={{ width: '100%', borderRadius: '6px' }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </>
          )}

          {typeOfModal === 'generateEmbeddedCode' && (
            <>
              <p className="install-subtitle">
                Copy and paste this code to your website (HTML editor, website
                template,themes etc)
              </p>
              <Card size="small" className="script-modal">
                <Row justify="center">
                  <Col span={4}>
                    <Button
                      onClick={copyCode}
                      type="primary"
                      className="btn-rounded full-width"
                    >
                      {!copy ? 'Copy' : 'Copied'}
                    </Button>
                  </Col>
                </Row>

                <Row justify="center">
                  <Col span={24} className="mt-8">
                    <pre className="d-flex flex-vertical" id="scriptCode">
                      <code>
                        &lt;link rel="preconnect"
                        href="https://fonts.googleapis.com" /&gt;
                        <br />
                        &lt;link rel="preconnect"
                        href="https://fonts.gstatic.com" crossOrigin /&gt;
                        <br />
                        &lt;link
                        href="https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&amp;family=Comfortaa:wght@300..700&amp;family=EB+Garamond:ital,wght@0,400..800;1,400..800&amp;family=Lexend:wght@100..900&amp;family=Lobster&amp;family=Lora:ital,wght@0,400..700;1,400..700&amp;family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&amp;family=Montserrat:ital,wght@0,100..900;1,100..900&amp;family=Nunito:ital,wght@0,200..1000;1,200..1000&amp;family=Oswald:wght@200..700&amp;family=Pacifico&amp;family=Playfair+Display:ital,wght@0,400..900;1,400..900&amp;family=Roboto+Mono:ital,wght@0,100..700;1,100..700&amp;family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&amp;family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap"
                        rel="stylesheet" /&gt;
                        <br />
                        &lt;script async src="
                        <span id="scriptUrlPlaceholder">{`${renderAppConfig.domain}platform/instaplug.js`}</span>
                        " onload='
                        <br />
                        &nbsp;window.renderApp(
                        {JSON.stringify(renderAppConfig, null, 2)});
                        <br />
                        '&gt;&lt;/script&gt;
                      </code>
                      <br />
                      <code>
                        &lt;div id="
                        <span id="scriptUrlPlaceholder">{selectedId}</span>
                        "&gt;&lt;/div&gt;
                      </code>
                      <br />
                    </pre>
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <ScriptGuidelines copyScript={copyCode} copy={copy} />
                  </Col>
                </Row>
              </Card>
            </>
          )}

          {typeOfModal === 'deleteWidget' && (
            <div className="instaplug-widget-wrapper">
              <p className="font-m">
                Are you sure you want to delete the widget?
              </p>
              <p className="text-14 m-0">
                Once deleted it cannot be recovered and no longer available in
                websites where it is used.
              </p>

              <div className="d-flex justify-end col-gap-8 full-width mt-16">
                <Button
                  type="primary"
                  danger
                  htmlType="submit"
                  className="all-btn btn-rounded"
                  disabled={deleteWidgetLoading}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="all-btn btn-rounded"
                  loading={deleteWidgetLoading}
                  onClick={handleDeleteWidget}
                >
                  Yes, Delete
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}

      <Layout className="dashboard">
        {widgetNotCreated ? (
          <div className="center">
            <div className="text-center">
              <img alt="logo" src="/logo.png" className="dashboard-logo" />
              <Title level={4}>Your widget can’t wait to be created!</Title>
              <p>
                Create a captivating widget with the help of ready-made
                templates or configure a unique widget from scratch.
              </p>
            </div>
            <div className="text-center">
              <Button
                icon={<PlusOutlined />}
                type="primary"
                className="ml-16 mt-8 all-btn"
                onClick={openCreateWidgetModal}
              >
                Create widget
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Card className="full-width mt-20 widget-info-card">
              <div className="d-flex justify-between align-center">
                <div className="d-flex justify-center flex-vertical ">
                  <div className="d-flex align-center col-gap-4">
                    <h1 className="m-0 text-24">Dashboard</h1>
                  </div>
                </div>
              </div>
              <Card className="mt-10 widget-info-card analytics-card-container full-height border-none">
                <Row gutter={[16, 16]}>
                  <Col sm={24} md={12} lg={8} xl={8}>
                    <Card className="full-height" hoverable>
                      <h1 className="m-0 text-16 mb-8">
                        Active plan information
                      </h1>
                      <Badge
                        size="default"
                        color={returnPlanColor}
                        count={
                          PLANS?.[currentUser?.subscriptionPlan?.key]?.label
                        }
                      />
                    </Card>
                  </Col>
                  <Col sm={24} md={12} lg={8} xl={8}>
                    <Card className="full-height" hoverable>
                      <h1 className="m-0 text-16 mb-8">
                        {widgetLimit === 0
                          ? 'Created Widgets'
                          : 'Widgets limit'}
                      </h1>

                      <span>
                        {widgetLimit === 0
                          ? usage
                          : `${usage} / ${widgetLimit}`}
                      </span>
                    </Card>
                  </Col>
                  {currentUser?.subscriptionPlan?.billingType !==
                    BILLING_TYPE?.ONE_TIME?.key && (
                    <Col sm={24} md={12} lg={8} xl={8}>
                      <Card className="full-height" hoverable>
                        <h1 className="m-0 text-16 mb-8">
                          Cancel subscription
                        </h1>
                        <Button
                          className="upgrade-btn cancel-subscription plan-details "
                          onClick={() => {
                            setMailSubject('cancel subscription');
                            setOpenUnsubscribeModal(true);
                          }}
                        >
                          Unsubscribe
                        </Button>
                      </Card>
                    </Col>
                  )}
                </Row>
              </Card>
            </Card>

            <Card
              className="widget-listing-card full-width mt-10"
              title={
                <div className="d-flex justify-between full-width">
                  <div className="d-flex col-gap-12 align-center min-h-40 align-center">
                    <h3 className="m-0">My widgets</h3>
                    {!includes(
                      [
                        PLANS?.BASIC_LIFE_TIME_DEAL?.key,
                        PLANS?.PRO_LIFE_TIME_DEAL?.key,
                        PLANS?.AGENCY_LIFE_TIME_DEAL?.key,
                      ],
                      currentUser?.subscriptionPlan?.key,
                    ) &&
                      hasAccessTo?.allowWidgetCreation && (
                        <Button
                          className="upgrade-btn d-flex col-gap-6"
                          icon={<PremiumIcon />}
                          onClick={() => {
                            GaEvent(GA_EVENT_NAMES.CLICK_ON_UPGRADE);
                            history?.push(ROUTES?.MANAGE_PRICING);
                          }}
                        >
                          Upgrade
                        </Button>
                      )}
                  </div>

                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    className="all-btn"
                    onClick={openCreateWidgetModal}
                  >
                    Create widget
                  </Button>
                </div>
              }
            >
              <Table
                onRow={handleEditWidget}
                columns={widgetColumns}
                dataSource={widgetList}
                rowKey={(record) => record?.id}
                loading={deleteWidgetLoading || fetchWidgets}
                onChange={handleTableChange}
                rowClassName="pointer"
                pagination={{
                  position: ['bottomCenter'],
                  current: getCurrent(offset, limit),
                  pageSize: limit,
                  total,
                }}
              />
            </Card>
          </>
        )}
      </Layout>
    </div>
  );
}

export default Dashboard;
