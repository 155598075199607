/* eslint-disable no-console */
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import Gleap from 'gleap';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Workbox } from 'workbox-window';
import client from './apollo';
import { AppContextProvider } from './AppContext';
import './index.css';
import Routes from './Routes';
import './styles/main.less';
import Themejson from './styles/style.json';

const VERSION = require('../package.json').version;

// eslint-disable-next-line no-console
console?.log('APP_VERSION', VERSION);

// eslint-disable-next-line no-undef
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/sw.js');

  wb.register();
}

if (process.env?.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: process.env?.REACT_APP_SENTRY_DSN,
    environment: process.env?.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  });
}
Gleap.initialize(process.env.REACT_APP_GLEAP_ID);
// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(document?.getElementById('root'));
root?.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <ConfigProvider theme={Themejson}>
        <Routes />
      </ConfigProvider>
    </AppContextProvider>
  </ApolloProvider>,
);
