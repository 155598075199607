import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../common/constants';

function CommonLogo({ src = '', classes = '' }) {
  return (
    <Link to={ROUTES?.MAIN} className="d-flex">
      <img alt="logo" className={`logo-header ${classes}`} src={src} />
    </Link>
  );
}

export default CommonLogo;
