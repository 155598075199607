import { gql } from '@apollo/client';

export const CREATE_WIDGET = gql`
  mutation CreateWidget($data: CreateWidgetInput!) {
    createWidget(data: $data) {
      message
      data {
        id
        name
        createdAt
        updatedAt
        template
      }
    }
  }
`;

export const UPDATE_WIDGET = gql`
  mutation UpdateWidget(
    $where: WidgetWhereUniqueInput!
    $data: UpdateWidgetInput
  ) {
    updateWidget(where: $where, data: $data) {
      message
      data {
        id
        name
        createdAt
        updatedAt
        template
      }
    }
  }
`;

export const DELETE_WIDGET = gql`
  mutation RemoveWidget($where: WidgetWhereUniqueInput!) {
    removeWidget(where: $where) {
      message
    }
  }
`;
