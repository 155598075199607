import { Button, Card } from 'antd';
import React from 'react';
import UserProfile from '../../../app/components/header/UserProfile';
import { CrossCircle } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import CommonLogo from '../../../components/CommonLogo';
import Portal from '../../../components/Portal';
import history from '../../../historyData';

function Failed() {
  return (
    <div className="d-flex align-center justify-center full-height payment-status-screen">
      <Portal portalId="header-title">
        <div className="full-height">
          <CommonLogo src="/logo-light.png" classes="full-logo" />
        </div>
      </Portal>
      <Portal portalId="profile">
        <UserProfile />
      </Portal>
      <Card className="payment-success-card">
        <CrossCircle className="check-circle" />
        <div className="success-content d-flex align-center flex-vertical">
          <h1 className="mb-0 mt-24">Payment failed</h1>
          <p>The payment got failed. Try again.</p>
          <Button
            className="all-btn full-width mt-16"
            type="primary"
            size="large"
            onClick={() => history?.replace(ROUTES?.MANAGE_PRICING)}
          >
            Try again
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Failed;
