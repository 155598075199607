import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { PremiumIcon } from '../../../assets/svg';
import {
  GA_EVENT_NAMES,
  ROUTES,
  SUPPORT_MAIL_ADDRESS,
} from '../../../common/constants';
import { GaEvent } from '../../../common/utils';
import history from '../../../historyData';
import '../feature-access.less';

function FeatureAccessModal() {
  const {
    state: { openFeatureAccessModal, mailSubject },
    toggleFeatureAccessModal,
  } = useContext(AppContext);
  const { status, info } = openFeatureAccessModal || {};

  const goToManagePricing = () => {
    GaEvent(GA_EVENT_NAMES.CLICK_ON_UPGRADE);
    toggleFeatureAccessModal({
      ...openFeatureAccessModal,
      status: false,
      info: '',
    });
    history?.push(ROUTES?.MANAGE_PRICING);
  };

  return (
    <Modal
      width={406}
      wrapClassName="feature-access-modal"
      open={status}
      footer={false}
      onCancel={() =>
        toggleFeatureAccessModal({
          ...openFeatureAccessModal,
          status: false,
          info: '',
        })
      }
      // eslint-disable-next-line no-undef
      getContainer={() => document?.getElementById('root')}
    >
      <div className="feature-wrapper d-flex flex-vertical justify-between p-10 full-height ">
        <div className="feature-modal-header">
          <PremiumIcon className="premium-icon" />
          Upgrade your plan to unlock this feature!
        </div>
        <div className="feature-modal-info">
          {info}
          <br />
          <div className="mt-8">
            Please contact us for more Information
            <a
              href={`mailto:${SUPPORT_MAIL_ADDRESS}?subject=Support for: ${mailSubject}`}
              target="_blank"
              rel="noreferrer"
              className="text-center"
            >
              <span className="font-bold"> support@instaplug.app</span>
            </a>
          </div>
        </div>
        <div className="d-flex justify-end">
          <Button
            type="default"
            onClick={goToManagePricing}
            className="upgrade-btn"
          >
            Upgrade plan
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default FeatureAccessModal;
