import { InstagramFilled } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { PremiumIcon } from '../../assets/svg';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  POPUP_FEATURES,
  SOURCE_TYPE,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';

const INSTAGRAM_CONNECT_URL = `https://api.instagram.com/oauth/authorize?client_id=186062514292928&scope=user_profile,user_media&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

function PersonalAccount({
  accountType,
  sourceData,
  headerData,
  disconnectAccount,
  getSource,
  personalHeaderLoading,
}) {
  const {
    state: { openFeatureAccessModal },
    toggleFeatureAccessModal,
  } = useContext(AppContext);
  const { id } = useParams();
  const url = `${INSTAGRAM_CONNECT_URL}&state=${id}/instagram`;
  const features = POPUP_FEATURES;
  const [childWindow, setChildWindow] = useState(null);
  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });
  const connectToPersonalAccount = () => {
    // eslint-disable-next-line no-undef
    const newChildWindow = window?.open(url, 'popUpWindow', features);
    setChildWindow(newChildWindow);
    GaEvent(GA_EVENT_NAMES.CONNECT_INSTAGRAM_PERSONAL_ACCOUNT, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
  };

  const handleDisconnectAccount = () => {
    disconnectAccount({
      variables: {
        where: {
          widgetId: id,
          sourceType: accountType,
        },
      },
    });
  };

  const IsConnected =
    sourceData &&
    sourceData?.sourceType === SOURCE_TYPE?.PERSONAL_ACCOUNT?.value;

  useEffect(() => {
    if (IsConnected) {
      return;
    }
    const interval = setInterval(() => {
      if (childWindow && childWindow?.closed) {
        setChildWindow(null);
        getSource({
          variables: {
            where: {
              sourceType: accountType,
              widgetId: id,
            },
          },
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [childWindow]);

  return (
    <Card
      title="Instagram Connect"
      className="feed-info-card"
      actions={[
        <>
          <Button
            key="business"
            type="primary"
            icon={
              <div className="d-flex mr-6 align-center col-gap-4">
                {!hasAccessTo?.allowIGPersonalFeed && (
                  <PremiumIcon className="h-16" />
                )}
                <InstagramFilled />
              </div>
            }
            className="all-btn d-flex align-center m-auto"
            onClick={() => {
              if (!hasAccessTo?.allowIGPersonalFeed) {
                toggleFeatureAccessModal({
                  ...openFeatureAccessModal,
                  status: true,
                  info: 'Connect to your personal instagram account.',
                });
                return;
              }
              if (IsConnected) {
                handleDisconnectAccount();
              } else {
                connectToPersonalAccount();
              }
            }}
            loading={personalHeaderLoading}
          >
            {IsConnected ? 'Disconnect Account' : 'Instagram Account'}{' '}
          </Button>
        </>,
      ]}
    >
      <LoaderComponent spinning={personalHeaderLoading} setHeight={10}>
        <Content>
          {IsConnected ? (
            <span>
              Successfully connected as <strong>@{headerData?.username}</strong>
            </span>
          ) : (
            <span>Authorize in your Instagram account to display profile.</span>
          )}
        </Content>
      </LoaderComponent>
    </Card>
  );
}

export default PersonalAccount;
