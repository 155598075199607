import { PictureFilled } from '@ant-design/icons';
import { Checkbox, Radio, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import classic from '../../assets/images/classic.png';
import tile from '../../assets/images/tile.png';
import { PremiumIcon } from '../../assets/svg';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  HOVER_POST_ELEMENTS,
  POST_ELEMENTS,
  POST_STYLE,
  notAllowedKeys,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';

const { Group } = Radio;

function PostStyle({ hasAccessTo = null }) {
  const {
    state,
    handlePostModule,
    toggleFeatureAccessModal,
    setMailSubject,
  } = useContext(AppContext);
  const { id } = useParams();
  const { postModule, openFeatureAccessModal, previewStyle } = state;
  const [isHover, setIsHover] = useState(false);
  const handlePostStyle = (event) => {
    const {
      target: { value },
    } = event;
    if (value === POST_STYLE.HOVER.value) {
      GaEvent(GA_EVENT_NAMES.HOVER_POST_STYLE, {
        [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
        [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
      });
    } else {
      GaEvent(GA_EVENT_NAMES.SIMPLE_POST_STYLE, {
        [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
        [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
      });
    }
    if (
      !hasAccessTo?.allowPostHoverEffect &&
      value === POST_STYLE?.HOVER?.value
    ) {
      setMailSubject('Show post with hover effect');
      toggleFeatureAccessModal({
        ...openFeatureAccessModal,
        status: true,
        info: 'Show post with hover effect',
      });
      return;
    }

    handlePostModule({ ...postModule, style: value });
  };

  const handlePostElement = (value) => {
    if (
      value?.some((key) => notAllowedKeys?.includes(key)) &&
      !hasAccessTo?.allowCommentLikeCount &&
      !hasAccessTo?.allowSharingOfPost
    ) {
      let shareKey = false;
      if (value?.some((key) => key === 'share')) {
        shareKey = true;
      }
      setMailSubject(
        shareKey
          ? 'Add Sharable functionality in post'
          : 'Show the count of Comments and Like on Post',
      );
      toggleFeatureAccessModal({
        ...openFeatureAccessModal,
        status: true,
        info: shareKey
          ? 'Show share button in post.'
          : 'Show like count and comment count in post.',
      });
      return;
    }
    handlePostModule({ ...postModule, postElement: value });
  };

  useEffect(() => {
    setIsHover(postModule?.style === POST_STYLE?.HOVER?.value ?? false);
  }, [postModule?.style]);

  const showPremiumIcon = (name) => {
    if (
      notAllowedKeys?.includes(name) &&
      !hasAccessTo?.allowSharingOfPost &&
      !hasAccessTo?.allowCommentLikeCount
    ) {
      return <PremiumIcon className="h-16" />;
    }
    return null;
  };

  const handleGaEvents = (value, hover) => {
    const eventMap = {
      [POST_ELEMENTS.likeCount.value]: {
        hover: GA_EVENT_NAMES.SHOW_HOVER_POST_LIKE_COUNT,
        noHover: GA_EVENT_NAMES.SHOW_SIMPLE_POST_LIKE_COUNT,
      },
      [POST_ELEMENTS.commentCount.value]: {
        hover: GA_EVENT_NAMES.SHOW_HOVER_POST_COMMENTS_COUNT,
        noHover: GA_EVENT_NAMES.SHOW_SIMPLE_POST_COMMENTS_COUNT,
      },
      [POST_ELEMENTS.text.value]: {
        hover: GA_EVENT_NAMES.SHOW_HOVER_POST_TEXT,
        noHover: GA_EVENT_NAMES.SHOW_SIMPLE_POST_TEXT,
      },
      [POST_ELEMENTS.user.value]: {
        noHover: GA_EVENT_NAMES.SHOW_SIMPLE_POST_USER,
      },
      [POST_ELEMENTS.date.value]: {
        noHover: GA_EVENT_NAMES.SHOW_SIMPLE_POST_DATE,
      },
      [POST_ELEMENTS.instagramLink.value]: {
        noHover: GA_EVENT_NAMES.SHOW_SIMPLE_POST_INSTAGRAM_LINK,
      },
      [POST_ELEMENTS.share.value]: {
        noHover: GA_EVENT_NAMES.SHOW_SIMPLE_POST_SHARE,
      },
    };

    const eventName = hover
      ? eventMap?.[value]?.hover
      : eventMap?.[value]?.noHover;
    if (eventName) {
      GaEvent(eventName, {
        [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
        [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
      });
    }
  };

  return (
    <div>
      <div className="action-preview-screen">
        <center>
          <img
            className="action-image"
            src={!isHover ? classic : tile}
            alt={<PictureFilled className="mt-80 icon-font" />}
          />
        </center>
      </div>
      <center className="mt-16 mb-16">
        <Group
          size="middle"
          optionType="button"
          buttonStyle="solid"
          onChange={(value) => handlePostStyle(value, 'style')}
          value={postModule?.style}
          className="d-flex justify-center"
        >
          {map(POST_STYLE, (item) => (
            <Radio value={item?.value} key={item?.value}>
              <div className="d-flex align-center col-gap-4">
                {!hasAccessTo?.allowPostHoverEffect &&
                  item?.value === POST_STYLE?.HOVER?.value && (
                    <PremiumIcon className="h-16" />
                  )}
                <span>{item?.label}</span>
              </div>
            </Radio>
          ))}
        </Group>
      </center>

      <div className="action-subtitle">Post Element</div>
      <Row gutter={[4, 4]}>
        <Checkbox.Group
          onChange={handlePostElement}
          value={postModule?.postElement}
        >
          <div>
            {isHover
              ? map(HOVER_POST_ELEMENTS, (post) => (
                  <div key={post?.value} className="full-width mb-8">
                    <Checkbox
                      className="text-color"
                      value={post?.value}
                      onClick={() => handleGaEvents(post?.value, isHover)}
                    >
                      {post?.name}
                    </Checkbox>
                  </div>
                ))
              : map(POST_ELEMENTS, (post) => (
                  <div key={post?.value} className="full-width mb-8">
                    <Checkbox
                      className="text-color"
                      value={post?.value}
                      onClick={() => handleGaEvents(post?.value, isHover)}
                    >
                      <div className="d-flex align-center leading-1">
                        <span className="pr-4 ">{post?.name}</span>
                        {showPremiumIcon(post?.value)}
                      </div>
                    </Checkbox>
                  </div>
                ))}
          </div>
        </Checkbox.Group>
      </Row>
    </div>
  );
}

export default PostStyle;
