import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SOURCE = gql`
  query GetSource($where: GetSourceInput!) {
    getSource(where: $where) {
      sourceId
      sourceType
      igSources
      widgetId
      businessSources {
        id
        username
        isSelected
      }
    }
  }
`;

export const GET_PERSONAL_MEDIA = gql`
  query GetPersonalMediaData($sourceId: ID!, $pagination: PaginationInput) {
    getPersonalMediaData(sourceId: $sourceId, pagination: $pagination) {
      count
      data {
        id
        username
        mediaType
        mediaUrl
        permalink
        caption
        timestamp
        children {
          id
          thumbnailUrl
          mediaType
          mediaUrl
          permalink
        }
        thumbnailUrl
      }
    }
  }
`;

export const GET_PERSONAL_PROFILE_HEADER = gql`
  query PersonalProfileData($sourceId: ID!) {
    personalProfileData(sourceId: $sourceId) {
      username
    }
  }
`;

export const BUSINESS_ACCOUNT_MEDIA = gql`
  query BusinessMediaData($sourceId: ID!, $pagination: PaginationInput) {
    businessMediaData(sourceId: $sourceId, pagination: $pagination) {
      count
      data {
        id
        username
        thumbnailUrl
        mediaType
        mediaUrl
        permalink
        commentsCount
        likeCount
        caption
        timestamp
        children {
          id
          thumbnailUrl
          mediaType
          mediaUrl
          permalink
        }
      }
      count
    }
  }
`;

export const BUSINESS_PROFILE = gql`
  query BusinessProfileData($sourceId: ID!) {
    businessProfileData(sourceId: $sourceId) {
      name
      iGAccount {
        id
        mediaCount
        username
        name
        followersCount
        followsCount
        website
        profilePictureUrl
        biography
      }
    }
  }
`;
