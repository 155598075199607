import { Input } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';

function FeedTitle() {
  const {
    state: { feedTitle },
    handleFeedTitle,
  } = useContext(AppContext);
  const handleInputFeedTitle = (event) => {
    const {
      target: { value },
    } = event;

    handleFeedTitle(value);
  };
  return (
    <>
      <Input
        className="full-width"
        onChange={handleInputFeedTitle}
        allowClear
        size="large"
        value={feedTitle}
        placeholder="Enter Title for Feed"
      />
      <p className="action-info">
        URLs, hashtags (with #) and Instagram usernames (with @) will be
        automatically displayed as clickable links.
      </p>
    </>
  );
}

export default FeedTitle;
