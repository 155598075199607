import { QuestionCircleOutlined } from '@ant-design/icons';
import { Card, Col, InputNumber, Row, Select, Slider, Switch, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import { AUTO_PLAY_OPTIONS } from '../../common/constants';

function SliderSetting() {
  const {
    state: { sliderSettings },
    handleSliderSettings,
  } = useContext(AppContext);
  const isFirstRender = useRef(true);
  const [inputValue, setInputValue] = useState({ ...sliderSettings });

  const onValueChange = (newValue, key) => {
    if (newValue < 0) {
      return;
    }
    setInputValue((prev) => ({ ...prev, [key]: newValue }));
  };

  useEffect(() => {
    if (isFirstRender?.current) {
      isFirstRender.current = false;
      return;
    }
    handleSliderSettings(inputValue);
  }, [inputValue]);

  return (
    <div className="full-width">
      <h3>Slider Setting</h3>
      <Card size="small" className="mt-8 mb-8">
        <Row>
          <Col span={20}>Arrow Control</Col>
          <Col span={4}>
            <Switch
              defaultChecked={sliderSettings?.arrowControl}
              onChange={(value) => onValueChange(value, 'arrowControl')}
            />
          </Col>
        </Row>
      </Card>
      <Card size="small" className="mt-8 mb-8">
        <Row>
          <Col span={20}>Drag Control</Col>
          <Col span={4}>
            <Switch
              defaultChecked={sliderSettings?.dragControl}
              onChange={(value) => onValueChange(value, 'dragControl')}
            />
          </Col>
        </Row>
      </Card>
      <Card size="small" className="mt-8 mb-8">
        <Row>
          <Col span={15}>Animation</Col>
          <Col span={8}>
            <InputNumber
              value={inputValue?.animationSpeed}
              onChange={(value) => onValueChange(value, 'animationSpeed')}
            />
          </Col>
          <Col span={24}>
            <Slider
              min={1}
              max={5}
              onChange={(value) => onValueChange(value, 'animationSpeed')}
              value={inputValue?.animationSpeed}
            />
          </Col>
        </Row>
      </Card>
      <Card size="small" className="mt-8 mb-8">
        <Row>
          <Col span={15}>
            Auto Play
            <Tooltip title="In sec">
              <QuestionCircleOutlined className="ml-8" />
            </Tooltip>
          </Col>
          <Col span={8}>
            <Select
              className="full-width"
              getPopupContainer={(triggerNode) => triggerNode?.parentNode}
              options={AUTO_PLAY_OPTIONS}
              onChange={(value) => onValueChange(value, 'autoPlay')}
              value={sliderSettings?.autoPlay}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default SliderSetting;
