import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_LAYOUT = gql`
  mutation createOrUpdateLayout($data: CreateOrUpdateLayout!) {
    createOrUpdateLayout(data: $data) {
      columnsAndRows {
        mode
        config {
          columns
          rows
          gap
          width
        }
      }
      feedTitle
      type
      sliderSettings {
        arrowControl
        dragControl
        animationSpeed
        autoPlay
      }
      headers {
        showHeaders
        config {
          profilePicture
          fullName
          userName
          verifiedBadge
          postsCount
          followersCount
          followingCount
          followButton
        }
      }
    }
  }
`;

export const UPDATE_POST_CONFIG = gql`
  mutation CreateOrUpdatePostConfig($data: CreateOrUpdatePostConfigInput!) {
    createOrUpdatePostConfig(data: $data) {
      style
      postElement {
        user
        date
        likeCount
        commentCount
        text
        share
        instagramLink
      }
      clickAction
      popUPSettings {
        user
        location
        followButton
        instagramLink
        likesCount
        share
        text
        comments
        date
      }
      callToAction {
        instagramUrl
        buttonUrl
        buttonLabel
      }
      postCustomStyle {
        fontColor
        fontFamily
        margin
        padding
        isDarkMode
        borderStyle
        borderColor
        borderWidth
      }
    }
  }
`;

export const PUBLISH_WIDGET = gql`
  mutation PublishWidget($where: PublishWidgetWhereInput!) {
    publishWidget(where: $where) {
      message
    }
  }
`;

export const UPDATE_POST_MODERATION_MEDIA = gql`
  mutation UpdateMediaData(
    $where: UpdateMediaDataWhereInputType!
    $data: UpdateMediaDataInputType
  ) {
    updateMediaData(where: $where, data: $data) {
      message
    }
  }
`;
