import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import WidgetLayout from './WidgetLayout';

const LayoutWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.WIDGET} component={WidgetLayout} />
  </Switch>
);

export default LayoutWrapper;
