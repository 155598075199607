import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Source from './Source';

const SourceWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.SOURCE} component={Source} />
  </Switch>
);

export default SourceWrapper;
