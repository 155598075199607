import { Modal } from 'antd';
import React from 'react';

export default function RouterPrompt(props) {
  const {
    title = 'Leave this page',
    description = 'There are some unpublished changes in this widget. They will not be reflected until you publish them.',
    okText = 'Confirm',
    cancelText = 'Cancel',
    handleOK,
    handleCancel,
    isPrompt,
  } = props;

  return isPrompt ? (
    <Modal
      title={title}
      open={isPrompt}
      onOk={handleOK}
      okText={okText}
      onCancel={handleCancel}
      cancelText={cancelText}
      className="router-prompt"
    >
      {description}
    </Modal>
  ) : null;
}
