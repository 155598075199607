import { Card, Col, Row, Select } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  LANG_OPTIONS,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';
import i18n from '../../i18n';

const { Option } = Select;

function Settings({ updateWidgets, widgetData = null }) {
  const { id } = useParams();
  const changeLanguage = (language) => {
    GaEvent(GA_EVENT_NAMES.LANGUAGE_SETTINGS, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LANGUAGE_CODE]: language,
    });
    i18n?.changeLanguage(language);
    updateWidgets({
      variables: { where: { id }, data: { name: widgetData?.name, language } },
    });
  };

  return (
    <div className="text-color">
      <h2>Settings</h2>
      <Card size="small" className="full-width" title="Select Language">
        <Row align="middle">
          <Col span={10}>Language</Col>
          <Col span={14}>
            <Select
              defaultValue={widgetData?.language}
              onChange={changeLanguage}
              className="full-width"
            >
              {map(LANG_OPTIONS, (lang) => (
                <Option value={lang?.code}>{lang?.name}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default Settings;
