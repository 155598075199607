import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import history from '../../historyData';
import Failed from './Failed';
import PricingList from './PricingList';
import Success from './Success';

const PricingWrapper = () => (
  <>
    <div className="common-dashboard-btn">
      <Button
        icon={<LeftOutlined />}
        type="link"
        className="all-btn leading-1"
        onClick={() => history?.push(ROUTES?.MAIN)}
      >
        Go back
      </Button>
    </div>
    <Switch>
      <Route exact path={ROUTES?.MANAGE_PRICING} component={PricingList} />
      <Route exact path={ROUTES?.SUCCESS} component={Success} />
      <Route exact path={ROUTES?.FAIL} component={Failed} />
    </Switch>
  </>
);

export default PricingWrapper;
