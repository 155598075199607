import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import { filter } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import { PremiumIcon } from '../../assets/svg';
import { GA_EVENT_NAMES, GA_EVENT_PARAMETERS } from '../../common/constants';
import { GaEvent } from '../../common/utils';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';
import FilterController from './components/FilterController';

function Filters({
  setShowFilters,
  filters = { show: [], hide: [] },
  setFilters,
  updateFilters,
  id = '',
}) {
  const {
    state: { openFeatureAccessModal },
    toggleFeatureAccessModal,
    setMailSubject,
  } = useContext(AppContext);
  const [filterData, setFilterData] = useState(false);
  const [filterKey, setFilterKey] = useState();
  const isFirstRender = useRef(true);
  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const handleBack = () => {
    setShowFilters(false);
  };

  const restrictionModal = (type) => {
    setMailSubject('Show post containing specific words or mentions');
    toggleFeatureAccessModal({
      ...openFeatureAccessModal,
      status: true,
      info: `${type} post containing specific words or mentions.`,
    });
  };

  const handleShowFilters = () => {
    if (!hasAccessTo?.allowHashtagAndMentions) {
      restrictionModal('Show');
      return;
    }
    setFilterData(true);
    setFilterKey('show');
    GaEvent(GA_EVENT_NAMES.SHOW_POST_FILTER, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
  };

  const handleHideFilters = () => {
    if (!hasAccessTo?.allowHashtagAndMentions) {
      restrictionModal('Hide');
      return;
    }
    setFilterData(true);
    setFilterKey('hide');
    GaEvent(GA_EVENT_NAMES.HIDE_POST_FILTER, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
  };

  const handleSubmitFilters = (formValues) => {
    setFilterData(false);
    const updatedFilterValues = {
      show: formValues?.show
        ? [...filters?.show, formValues?.show]
        : [...filters?.show],
      hide: formValues?.hide
        ? [...filters?.hide, formValues?.hide]
        : [...filters?.hide],
      totalNumberOfPost: filters?.totalNumberOfPost,
    };
    setFilters({ ...filters, ...updatedFilterValues });
  };

  const handleCancel = () => {
    setFilterData(false);
  };

  const handleTotalPost = (value) => {
    setFilters({ ...filters, totalNumberOfPost: value });
  };

  const handleDeleteShowFilter = (currentIndex, type) => {
    const updatedFilter = filter(
      filters?.[type],
      (_, filterIndex) => filterIndex !== currentIndex,
    );
    setFilters({
      ...filters,
      [type]: updatedFilter,
    });
  };

  useEffect(() => {
    if (isFirstRender?.current) {
      isFirstRender.current = false;
      return;
    }
    updateFilters({
      variables: {
        data: {
          widgetId: id,
          filters,
        },
      },
    });
  }, [filters]);

  return (
    <div className="filter-section">
      {filterData ? (
        <div className="full-width">
          <h3>Filters</h3>
          <Form className="full-width" onFinish={handleSubmitFilters}>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item name={filterKey} rules={[{ required: true }]}>
                  <Input placeholder="Enter @username or #hashtag" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Button
                  onClick={handleCancel}
                  className="full-width all-btn"
                  danger
                  type="primary"
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="full-width all-btn"
                  >
                    Done
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <>
          <Space direction="horizontal" className="d-flex justify-between">
            <h3 className="mr-64 full-width">Filters</h3>
            <Button
              onClick={handleBack}
              type="primary"
              danger
              className="text-color ml-80 all-btn"
            >
              Back
            </Button>
          </Space>

          <div>
            <h4 className="mb-16">Show post containing these words</h4>
            <div className="full-width">
              <FilterController
                data={filters?.show}
                handleDeleteShowFilter={(event) =>
                  handleDeleteShowFilter(event, 'show')
                }
              />
            </div>
            <Button
              className="full-width all-btn"
              type="primary"
              onClick={handleShowFilters}
            >
              <div className="d-flex justify-center col-gap-6 align-center ">
                {!hasAccessTo?.allowHashtagAndMentions && (
                  <PremiumIcon className="h-16" />
                )}
                <span className="leading-1">Add Filter</span>
                <PlusOutlined />
              </div>
            </Button>
          </div>

          <div>
            <h4 className="mb-16">Hide post containing these words</h4>
            <div className="full-width">
              <FilterController
                data={filters?.hide}
                handleDeleteShowFilter={(event) =>
                  handleDeleteShowFilter(event, 'hide')
                }
              />
            </div>
            <Button
              className="full-width all-btn"
              type="primary"
              onClick={handleHideFilters}
            >
              <div className="d-flex justify-center col-gap-6 align-center ">
                {!hasAccessTo?.allowHashtagAndMentions && (
                  <PremiumIcon className="h-16" />
                )}
                <span className="leading-1">Add Filter</span>
                <PlusOutlined />
              </div>
            </Button>
          </div>

          <div>
            <Row gutter={[8, 8]}>
              <Col>
                <Card size="small" className="mt-24">
                  <Row align="middle">
                    <Col span={16}>Total number of post to display</Col>
                    <Col span={8}>
                      <InputNumber
                        className="full-width"
                        onChange={handleTotalPost}
                        type="number"
                        min={0}
                        defaultValue={filters?.totalNumberOfPost}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col>
                <p className="card-width">
                  Set the required number to restrict the count of loaded posts.
                  Leave this option empty or 0 to show all available posts.
                </p>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
}

export default Filters;
