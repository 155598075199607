import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { map } from 'lodash';
import React from 'react';

function FilterController({ data, handleDeleteShowFilter }) {
  return map(data, (item, index) => (
    <div className="filter-controller" key={`${item}-${index}`}>
      <Card size="small" className="mb-8 font-bold">
        {item}
      </Card>
      <Button
        className="delete-filter-icon pointer "
        icon={<DeleteOutlined />}
        onClick={() => handleDeleteShowFilter(index)}
      />
    </div>
  ));
}

export default FilterController;
