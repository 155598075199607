import { Button, Modal } from 'antd';
import { includes } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { AppContext } from '../../../AppContext';
import { PLANS, ROUTES, SUPPORT_MAIL_ADDRESS } from '../../../common/constants';
import history from '../../../historyData';
import '../feature-access.less';
import useFeatureAccess from '../hooks/useFeatureAccess';

function TotalWidgetAccess({ open = true, setOpen }) {
  const {
    state: { currentUser, mailSubject },
  } = useContext(AppContext);
  const { widgetLimitAndUsage } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const returnText = useMemo(() => {
    const commonText = `You have created max number of widget for your ${
      PLANS?.[currentUser?.subscriptionPlan?.key]?.label
    }.`;
    if (
      !includes(
        [
          PLANS?.AGENCY_LIFE_TIME_DEAL?.key,
          PLANS?.BASIC_LIFE_TIME_DEAL?.key,
          PLANS?.PRO_LIFE_TIME_DEAL?.key,
        ],
        currentUser?.subscriptionPlan?.key,
      )
    ) {
      return ' You can create only 1 widget on the Free plan. Upgrade your plan to increase the limit or just delete the existing widget.';
    }
    if (
      includes(
        [PLANS?.AGENCY_LIFE_TIME_DEAL?.key],
        currentUser?.subscriptionPlan?.key,
      )
    ) {
      return `${commonText} Please connect to the support team for increase limit.`;
    }
    return commonText;
  }, [currentUser?.subscriptionPlan?.key]);

  return (
    <Modal
      width={550}
      wrapClassName="widget-limit-modal"
      open={open}
      footer={false}
      centered
      onCancel={() => setOpen(false)}
      // eslint-disable-next-line no-undef
      getContainer={() => document?.getElementById('root')}
    >
      <div className="widget-count-reached">
        <span>
          {widgetLimitAndUsage?.WIDGET_CREATION_LIMIT?.usage} /{' '}
          {widgetLimitAndUsage?.WIDGET_CREATION_LIMIT?.limit}
        </span>
      </div>
      <h2 className="heading">Instplug widget limit reached</h2>

      <div className="access-info">{returnText}</div>

      {includes(
        [
          PLANS?.FREE_PLAN?.key,
          PLANS?.BASIC_LIFE_TIME_DEAL?.key,
          PLANS?.PRO_LIFE_TIME_DEAL?.key,
        ],
        currentUser?.subscriptionPlan?.key,
      ) && (
        <Button
          className="upgrade-btn"
          type="default"
          size="large"
          onClick={() => {
            setOpen(false);
            history?.push(ROUTES?.MANAGE_PRICING);
          }}
        >
          Increase widget limit
        </Button>
      )}

      <div className="mt-16 text-center">
        <div>Please contact us for more Information</div>
        <a
          href={`mailto:${SUPPORT_MAIL_ADDRESS}?subject=Support for: ${mailSubject}`}
          target="_blank"
          rel="noreferrer"
          className="text-center"
        >
          <span className="font-bold">{SUPPORT_MAIL_ADDRESS}</span>
        </a>
      </div>
    </Modal>
  );
}

export default TotalWidgetAccess;
