// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider(app);
const facebookProvider = new FacebookAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account',
});
facebookProvider.setCustomParameters({
  display: 'popup',
});

export const auth = getAuth();
export const fbauth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithFacebookPopup = () =>
  signInWithPopup(fbauth, facebookProvider);
