import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import ResetPopUp from '../modules/auth/ResetPopUp';
import DashboardWrapper from '../modules/Dashboard';
import LayoutWrapper from '../modules/Layout';
import PostWrapper from '../modules/Post';
import PricingWrapper from '../modules/Pricing';
import ProfileWrapper from '../modules/profile';
import SampleWrapper from '../modules/sample';
import SourceWrapper from '../modules/Source';
import InstagramConnect from '../modules/Source/InstagramConnect';
import WidgetWrapper from '../modules/Widget';

const ContentRoutes = () => (
  <Switch>
    <Route path={ROUTES?.MAIN} exact component={DashboardWrapper} />
    <Route path={ROUTES?.WIDGET} component={WidgetWrapper} />
    <Route path={ROUTES?.SOURCE} component={SourceWrapper} />
    <Route path={ROUTES?.SOURCE} component={LayoutWrapper} />
    <Route path={ROUTES?.SOURCE} component={PostWrapper} />
    <Route path={ROUTES?.SAMPLE} component={SampleWrapper} />
    <Route path={ROUTES?.PROFILE} component={ProfileWrapper} />
    <Route path={ROUTES?.MANAGE_PRICING} component={PricingWrapper} />
    <Route path={ROUTES?.SUCCESS} component={PricingWrapper} />
    <Route path={ROUTES?.FAIL} component={PricingWrapper} />
    <Route
      exact
      path={ROUTES?.INSTAGRAM_CONNECT}
      component={InstagramConnect}
    />

    <Route path="*" exact component={Error404} />
    <Route path={ROUTES?.RESETPOPUP} component={ResetPopUp} />
  </Switch>
);

export default ContentRoutes;
