import {
  BulbOutlined,
  DownOutlined,
  IdcardOutlined,
  LoginOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Space } from 'antd';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { GA_EVENT_NAMES, ROUTES } from '../../../common/constants';
import { GaEvent } from '../../../common/utils';

const UserProfile = () => {
  const { pathname } = useLocation();
  const { getCurrentUser, setOnboardModal } = useContext(AppContext);
  const user = getCurrentUser();
  const history = useHistory();

  const items = [
    {
      key: 'profile',
      label: (
        <Button type="link" size="small">
          My Profile
        </Button>
      ),
      onClick: () => {
        history?.push(`${ROUTES?.PROFILE}/${user?.id}`);
        GaEvent(GA_EVENT_NAMES.USER_PROFILE);
      },
      icon: <IdcardOutlined />,
    },
    {
      key: 'logout',
      label: (
        <Button type="link" size="small">
          Logout
        </Button>
      ),
      onClick: () => {
        history?.push(ROUTES.LOGOUT);
        GaEvent(GA_EVENT_NAMES.LOGOUT_APP);
      },
      icon: <LoginOutlined />,
    },
    {
      /* user can click and see on board tutorial again */
      key: 'tutorial',
      label: (
        <Button type="link" size="small">
          Tutorial
        </Button>
      ),
      onClick: () => {
        setOnboardModal(true);
      },
      icon: <BulbOutlined />,
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
      trigger={['click']}
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        <Avatar
          icon={!user?.email?.[0] && <UserOutlined />}
          alt="Avatar"
        >{`${user?.email?.[0]?.toUpperCase()}`}</Avatar>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;
