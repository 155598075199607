import { HeartOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import { filter, includes, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CommentIcon, MultiplePostIcon, VideoIcon } from '../../assets/svg';
import {
  BREAKPOINTS,
  MEDIA_TYPE,
  POST_ACTION,
  POST_ELEMENTS,
  POST_STYLE,
} from '../../common/constants';
import { convertToCompactFormat } from '../../common/utils';
import PostFooter from './components/PostFooter';
import PostHeader from './components/PostHeader';

function PostGrid({
  profile,
  fetchMore,
  widgetId,
  setOpenPreviewModal,
  setCurrentPostId,
  context,
  isMobile = false,
  hasAccessTo,
  domain,
  darkMode = false,
  sourceType,
  sourceData,
}) {
  const {
    state: {
      previewLayoutObject: { col, row, gap },
      postData,
      postCount,
      postModule: { style, postElement, clickAction, postCustomStyle },
    },
  } = useContext(context);

  const { borderColor, borderStyle, borderWidth, fontColor, fontFamily } =
    postCustomStyle || {};

  const isHover =
    hasAccessTo?.allowPostHoverEffect && style === POST_STYLE?.HOVER?.value;
  const [mQuery, setMQuery] = useState({
    // eslint-disable-next-line no-undef
    isMobile: window.innerWidth < BREAKPOINTS?.mobile,
    // eslint-disable-next-line no-undef
    isTablet: window.innerWidth < BREAKPOINTS?.tablet,
  });

  const loadMoreData = async () => {
    const variables = {
      pagination: {
        limit: col * row,
        offset: postData?.length,
      },
    };
    if (sourceType && sourceData) {
      variables.sourceId = sourceData?.sourceId;
    } else {
      variables.widgetId = widgetId;
    }
    await fetchMore({
      variables,
    });
  };
  const returnDynamicColumns = useMemo(() => {
    if (postCount && postCount > 0) {
      return postCount > col ? col : postCount;
    }
    return col;
  }, [col, postCount, row]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const mediaQuery = window?.matchMedia(
      `(max-width: ${BREAKPOINTS?.mobile}px)`,
    );
    const mediaListener = ({ matches }) =>
      setMQuery((prev) => ({ ...prev, isMobile: matches }));
    mediaQuery?.addListener(mediaListener);
    return () => mediaQuery?.removeListener(mediaListener);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const mediaQuery = window?.matchMedia(
      `(max-width: ${BREAKPOINTS?.tablet}px)`,
    );
    const mediaListener = ({ matches }) =>
      setMQuery((prev) => ({ ...prev, isTablet: matches }));
    mediaQuery?.addListener(mediaListener);
    return () => mediaQuery?.removeListener(mediaListener);
  }, []);

  const returnCols = useMemo(() => {
    if (isMobile) {
      // this state is for checking when user toggle between mobile and desktop mode
      return 1;
    }
    if (mQuery?.isMobile) {
      return 2;
    }
    if (mQuery?.isTablet) {
      return 3;
    }
    return returnDynamicColumns;
  }, [mQuery, isMobile, returnDynamicColumns]);

  const dynamicStyle = {
    gridTemplateColumns: `repeat(${returnCols},1fr)`,
    gap: `${gap}px`,
  };

  const buttonStyles = { backgroundColor: 'white', color: 'black' };

  const controlPostElement = (section) => includes(postElement, section);

  const POST_HEADER_ELEMENT = ['user', 'date', 'instagramLink'];

  const isHeaderElementSelected = POST_HEADER_ELEMENT?.some((value) =>
    postElement?.includes(value),
  );

  const isPostFooterElementSelected = filter(
    POST_ELEMENTS,
    ({ value }) => !POST_HEADER_ELEMENT?.includes(value),
  )?.some(({ value }) => postElement?.includes(value));

  const handleClickOnPost = (post) => {
    setCurrentPostId(post?.id);
    if (clickAction === POST_ACTION?.POPUP?.value) {
      setOpenPreviewModal(true);
      return;
    }

    if (clickAction === POST_ACTION?.INSTAGRAM?.value) {
      // eslint-disable-next-line no-undef
      window?.open(post?.permalink, '_blank');
      return;
    }

    return false;
  };

  const isLikeCount = (item) => {
    const conditionStatus =
      controlPostElement('likeCount') &&
      'likeCount' in item &&
      item?.commentsCount !== null &&
      hasAccessTo?.allowCommentLikeCount;
    return conditionStatus;
  };

  const isCommentCount = (item) => {
    const conditionStatus =
      controlPostElement('commentCount') &&
      'commentsCount' in item &&
      item?.commentsCount !== null &&
      hasAccessTo?.allowCommentLikeCount;
    return conditionStatus;
  };

  const isCaption = controlPostElement('text') && col <= 7;

  return (
    <>
      <div className="post-grid-wrapper" style={dynamicStyle}>
        {map(postData, (item) => (
          <div
            className={`post-wrapper ${!isHover ? 'add-border' : ''}`}
            style={{
              borderWidth,
              borderColor,
              borderStyle,
            }}
          >
            {!isHover && isHeaderElementSelected && (
              <PostHeader
                profile={profile}
                postData={item}
                controlPostElement={controlPostElement}
                col={col}
                context={context}
              />
            )}
            <div
              className="post pointer d-flex justify-center post-aspect-ratio"
              onClick={() => handleClickOnPost(item)}
            >
              {item?.children && (
                <div className="video-icon">
                  <MultiplePostIcon />
                </div>
              )}
              {item?.mediaType === MEDIA_TYPE?.VIDEO?.value ? (
                <>
                  <div className="video-icon">
                    <VideoIcon />
                  </div>
                  {item?.thumbnailUrl ? (
                    <Image
                      className="instagram-post-thumbnail"
                      src={item?.thumbnailUrl}
                      preview={false}
                    />
                  ) : (
                    <PlayCircleOutlined className="text-color font-3xl" />
                  )}
                </>
              ) : (
                <Image
                  className="instagram-post-image"
                  src={item?.mediaUrl}
                  preview={false}
                />
              )}

              {isHover && isPostFooterElementSelected && (
                <div
                  style={{ color: fontColor, fontFamily }}
                  className="post-overlay-info d-flex flex-vertical align-center justify-center"
                  onClick={() => handleClickOnPost(item)}
                >
                  <div className="post-overlay-inner-wrapper d-flex mb-12">
                    {isLikeCount(item) && (
                      <div className="d-flex mr-12 col-gap-4">
                        <span>
                          <HeartOutlined className="post-overlay-icon" />
                        </span>
                        <span className="font-m">
                          {convertToCompactFormat(item?.likeCount)}
                        </span>
                      </div>
                    )}

                    {isCommentCount(item) && (
                      <div className="d-flex col-gap-4">
                        <span>
                          <CommentIcon
                            className="post-overlay-icon"
                            fill={fontColor}
                          />
                        </span>
                        <span className="font-m">
                          {convertToCompactFormat(item?.commentsCount)}
                        </span>
                      </div>
                    )}
                  </div>
                  {isCaption && (
                    <div className="post-caption  pl-12 pr-12">
                      <p className="text-center font-m">{item?.caption}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            {!isHover && (
              <PostFooter
                postData={item}
                controlPostElement={controlPostElement}
                col={col}
                darkMode={darkMode}
                context={context}
              />
            )}
          </div>
        ))}
      </div>
      {postCount > postData?.length && (
        <div className="d-flex justify-center align-center width-full mt-12">
          <Button
            onClick={loadMoreData}
            type="primary"
            className="all-btn mb-12"
            style={darkMode ? buttonStyles : undefined}
          >
            Load more
          </Button>
        </div>
      )}
      {hasAccessTo?.allowWaterMark && (
        <div className="instaplug-watermark grid mb-16">
          {
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <a href="https://instaplug.app" target="_blank" rel="noreferrer">
              <Image
                src={domain ? `${domain}logo.png` : '/logo.png'}
                preview={false}
              />
            </a>
          }
        </div>
      )}
    </>
  );
}

export default PostGrid;
