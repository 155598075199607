import { InstagramOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import { includes } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { convertToCompactFormat } from '../../../common/utils';
import i18n from '../../../i18n';

function ProfilePreviewHeader({
  headerController = [],
  profile = null,
  language = null,
  context,
}) {
  const {
    state: {
      postModule: { postCustomStyle },
    },
  } = useContext(context);

  const { fontFamily, fontColor, padding, margin, isDarkMode } =
    postCustomStyle || {};
  const { t } = i18n;
  const controlHeader = (section) => includes(headerController, section);
  useEffect(() => {
    if (language) {
      i18n?.changeLanguage(language);
    }
  }, [language]);
  const instaButton = {
    color: 'black',
    backgroundColor: 'white',
  };
  const fontStyles = {
    fontFamily,
    color: fontColor,
  };

  const fontFullStyles = {
    ...fontStyles,
    padding: `${padding}px`,
    margin: `${margin}px`,
  };
  if (
    (profile?.iGAccount?.username || profile?.username) &&
    !profile?.profilePictureUrl
  ) {
    return (
      <div
        className={`widget-preview-header ${
          isDarkMode ? 'is-dark' : ''
        } d-flex w-full p-12 align-center justify-center col-gap-40`}
      >
        <div className=" d-flex align-center justify-center ">
          <div className="user-info">
            <div className="user-full-name">
              {controlHeader('userName') && (
                <span style={fontStyles}>
                  @{profile?.iGAccount?.username || profile?.username}
                </span>
              )}
            </div>
          </div>
        </div>

        {controlHeader('followButton') && (
          <div>
            <Button
              type="primary"
              className="rounded-s d-flex align-center"
              icon={<InstagramOutlined />}
              onClick={() => {
                // eslint-disable-next-line no-undef
                const win = window?.open(
                  `https://www.instagram.com/${profile?.username}`,
                  '_blank',
                );
                win?.focus();
              }}
            >
              {t('Follow')}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`widget-preview-header ${
        isDarkMode ? 'is-dark' : ''
      } d-flex w-full p-12 align-center justify-center col-gap-40`}
    >
      <div className=" d-flex align-center justify-center" style={fontStyles}>
        {controlHeader('profilePicture') && (
          <div className="profile-photo mr-12">
            <Image src={profile?.profilePictureUrl} preview={false} />
          </div>
        )}

        <div className="user-info" style={fontFullStyles}>
          <div className="user-full-name">
            {controlHeader('fullName') && <span>{profile?.name} </span>}
            {/* kept for future use */}
            {/* {controlHeader('verifiedBadge') && (
              <span>
                <VerifiedIcon />
              </span>
            )} */}
          </div>
          {controlHeader('userName') && (
            <div className="user-name">@{profile?.username}</div>
          )}
        </div>
      </div>

      <div className="user-following-info d-flex col-gap-20">
        {(controlHeader('mediaCount') || controlHeader('postsCount')) && (
          <div className="user-count-wrapper" style={fontFullStyles}>
            <div className="font-bold font-m text-center">
              {profile?.mediaCount?.toLocaleString()}
            </div>
            <div className="font-s text-center"> {t('Posts')}</div>
          </div>
        )}
        {controlHeader('followersCount') && (
          <div className="user-count-wrapper" style={fontFullStyles}>
            <div className="font-bold font-m text-center">
              {convertToCompactFormat(profile?.followersCount)}
            </div>
            <div className="font-s text-center">{t('Followers')} </div>
          </div>
        )}
        {controlHeader('followingCount') && (
          <div className="user-count-wrapper" style={fontFullStyles}>
            <div className="font-bold font-m text-center">
              {profile?.followsCount?.toLocaleString()}
            </div>
            <div className="font-s text-center">{t('Following')}</div>
          </div>
        )}
      </div>
      {controlHeader('followButton') && (
        <div>
          <Button
            type="primary"
            style={isDarkMode ? instaButton : undefined}
            className="rounded-s d-flex align-center"
            icon={<InstagramOutlined />}
            onClick={() => {
              // eslint-disable-next-line no-undef
              const win = window?.open(
                `https://www.instagram.com/${profile?.username}`,
                '_blank',
              );
              win?.focus();
            }}
          >
            {t('Follow')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default ProfilePreviewHeader;
