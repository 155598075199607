import { gql } from '@apollo/client';

const USER_PROFILE = gql`
  query UserProfile {
    userProfile {
      firstName
      lastName
      dateOfBirth
      occupation
      phoneNo
      id
      email
      roles
      authProvider
      authRefData
      authRefId
      subscriptionFeatures
      subscriptionPlan
      isRegisteredCustomer
      isNewUser
      userUpgradedSubscription {
        key
        status
      }
      userSubscription
      userWidgetQuota {
        limit
        usage
      }
    }
  }
`;
export default USER_PROFILE;
