import React, { useEffect } from 'react';

const Timer = ({
  seconds,
  minutes,
  setMinutes,
  setSeconds,
  setTimerComplete,
}) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setTimerComplete(true);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <>
      {formattedMinutes}:{formattedSeconds}
    </>
  );
};

export default Timer;
