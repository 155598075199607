import { Button } from 'antd';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';
import { MuteOff, MuteOn } from '../../../assets/svg';

function CustomVideoPlayer({ url }) {
  const { ref, inView } = useInView({ threshold: 0.5 });
  const [isMute, setIsMute] = useState(true);
  return (
    <div ref={ref} className="video-wrapper">
      <ReactPlayer
        height="100%"
        width="100%"
        muted={isMute}
        playsinline
        playing={inView}
        loop
        url={url}
      />
      <Button
        className="mute-button d-flex justify-center align-center"
        icon={!isMute ? <MuteOn /> : <MuteOff />}
        onClick={() => setIsMute(!isMute)}
      />
    </div>
  );
}

export default CustomVideoPlayer;
