import { Card } from 'antd';
import React from 'react';

function ResetPopUp() {
  return (
    <div className="auth-bg">
      <center>
        <h1>InstaPlug</h1>
      </center>
      <div>
        <Card className="full-width">
          <h1>
            <center>Reset Password</center>
          </h1>
          <h4>
            <center>
              If the Email you specified exists in our system, we've sent a
              password reset link to it.
            </center>
          </h4>
        </Card>
      </div>
    </div>
  );
}

export default ResetPopUp;
