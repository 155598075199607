import { RightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Col, message, Row, Select } from 'antd';
import { debounce, includes, isNull, map } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { PremiumIcon } from '../../assets/svg';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  POST_ACTION,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';
import useFeatureAccess from '../FeatureAccess/hooks/useFeatureAccess';
import { UPDATE_POST_CONFIG } from '../Widget/graphql/Mutations';
import PostController from './PostController';

const { Option } = Select;

function WidgetPost({ getWidget, sourceData }) {
  const { id } = useParams();
  const {
    state,
    handlePostModule,
    toggleFeatureAccessModal,
    setMailSubject,
    setIsPostModerationPreview,
    setShowSidebar,
  } = useContext(AppContext);
  const { postModule, openFeatureAccessModal, previewStyle } = state;
  const [popUpSetting, setPopUpSetting] = useState(false);
  const [actionCall, setActionCall] = useState(false);
  const [customCss, setCustomCss] = useState(false);
  const [postStyle, setPostStyle] = useState(false);
  const [inEdit, setInEdit] = useState(false);
  const isFirstRender = useRef(true);
  const { widgetAccessConfig: hasAccessTo } = useFeatureAccess({
    contextToUse: AppContext,
  });

  const controlPostElement = (section) =>
    includes(postModule?.postElement, section);

  const controlPopUpElement = (section) =>
    includes(postModule?.popUPSettings, section);

  const [updatePostConfig] = useMutation(UPDATE_POST_CONFIG, {
    onCompleted() {
      getWidget({
        variables: {
          widgetId: id,
        },
      });
    },
    onError() {},
  });

  const debounceUpdatePostConfig = useMemo(
    () => debounce(updatePostConfig, 500),
    [updatePostConfig],
  );

  const handlePostStyle = () => {
    setPostStyle(true);
    GaEvent(GA_EVENT_NAMES?.POST_STYLE_SETTINGS, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
  };

  const handlePostBack = (section) => {
    GaEvent(section, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });

    switch (section) {
      case GA_EVENT_NAMES.BACK_BUTTON_POST_STYLE:
        setPostStyle(false);
        break;
      case GA_EVENT_NAMES.BACK_BUTTON_POPUP:
        setPopUpSetting(false);
        break;
      case GA_EVENT_NAMES.BACK_BUTTON_CTA_ACTION_BUTTON:
        setActionCall(false);
        break;
      default:
        break;
    }
  };

  const postPopupIsRestricted = (value = '') => {
    const popupText =
      (value === 'customCss' &&
        'Enhance your posts with Custom CSS styling for a personalized look and feel.') ||
      ((value === 'postClickAction' || value === 'postPopUpSetting') &&
        'Show posts in scrollable popup with reels and multiple slides/images.') ||
      (value === 'postModeration' &&
        'Enhance your feed with Post Moderation.With post moderation, you can control the content that appears in your feed.');
    toggleFeatureAccessModal({
      ...openFeatureAccessModal,
      status: true,
      info: popupText,
    });
  };

  const handlePopupSetting = () => {
    GaEvent(GA_EVENT_NAMES.POST_POPUP_ELEMENTS, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
    if (!hasAccessTo?.allowPostPopup) {
      setMailSubject('Show Post popup when clicking on post');
      postPopupIsRestricted('postPopUpSetting');
      return;
    }
    setPopUpSetting(true);
  };

  const handleActionCall = () => {
    GaEvent(GA_EVENT_NAMES.CALL_TO_ACTION, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
    setActionCall(true);
  };
  const handleInEditAction = () => {
    setInEdit(true);
  };

  const handleClickActionChange = (value) => {
    if (value === POST_ACTION?.POPUP?.value && !hasAccessTo?.allowPostPopup) {
      setMailSubject('Show Post popup when clicking on post');
      postPopupIsRestricted('postClickAction');
      return;
    }
    handlePostModule({ ...postModule, clickAction: value });
  };

  const handleCustomCss = () => {
    if (!hasAccessTo?.allowCustomCss) {
      setMailSubject('Show Post popup when clicking on post');
      postPopupIsRestricted('customCss');
      return;
    }
    setCustomCss(true);
  };

  const handlePostModeration = () => {
    if (!hasAccessTo?.allowPostModeration) {
      setMailSubject('Show Post popup when clicking on post');
      postPopupIsRestricted('postModeration');
      return;
    }
    if (isNull(sourceData)) {
      message.error(
        'Please connect your account or select the connected account from source',
      );
      return;
    }

    setIsPostModerationPreview(true);
    setShowSidebar(false);
  };

  const post = postStyle && 'Post Style';
  const popup = popUpSetting && 'Pop up Setting';
  const action = actionCall && 'Action Button';
  const postCustomCss = customCss && 'Custom CSS';

  const postMainScreen =
    !popUpSetting && !actionCall && !postStyle && !customCss;

  const titleCheck = !popUpSetting && !actionCall && !postStyle && !customCss;
  const otherButtonTitle =
    !inEdit && (popup || action || post || postCustomCss);

  const toShowBackButton =
    (popUpSetting || actionCall || postStyle || customCss) && !inEdit;

  const { __typename, ...restCustomStyle } = postModule?.postCustomStyle || {};

  const updatePostConfigSettings = useCallback(() => {
    const withoutButtonId = postModule?.callToAction?.map((item) => {
      const { id: buttonId, ...rest } = item;
      return rest;
    });
    const payload = {
      widgetId: id,
      style: postModule?.style,
      postElement: {
        user: controlPostElement('user'),
        date: controlPostElement('date'),
        likeCount: controlPostElement('likeCount'),
        commentCount: controlPostElement('commentCount'),
        text: controlPostElement('text'),
        share: controlPostElement('share'),
        instagramLink: controlPostElement('instagramLink'),
      },
      clickAction: postModule?.clickAction,
      popUPSettings: {
        user: controlPopUpElement('user'),
        location: controlPopUpElement('location'),
        followButton: controlPopUpElement('followButton'),
        instagramLink: controlPopUpElement('instagramLink'),
        likesCount: controlPopUpElement('likesCount'),
        share: controlPopUpElement('share'),
        text: controlPopUpElement('text'),
        comments: controlPopUpElement('comments'),
        date: controlPopUpElement('date'),
      },
      callToAction: withoutButtonId,
      postCustomStyle: { ...restCustomStyle },
    };
    try {
      debounceUpdatePostConfig({
        variables: {
          data: {
            ...payload,
          },
        },
      });
    } catch (error) {
      return false;
    }
  }, [postModule]);

  useEffect(() => {
    if (isFirstRender?.current) {
      isFirstRender.current = false;
      return;
    }
    updatePostConfigSettings();
  }, [postModule]);

  return (
    <Row justify="space-between" className="text-color">
      <Col span={17}>
        <h2>{titleCheck ? 'Post' : otherButtonTitle}</h2>
      </Col>
      {toShowBackButton && (
        <Col>
          <Button
            onClick={() => {
              if (postStyle) {
                handlePostBack(GA_EVENT_NAMES.BACK_BUTTON_POST_STYLE);
              } else if (popUpSetting) {
                handlePostBack(GA_EVENT_NAMES.BACK_BUTTON_POPUP);
              } else if (actionCall) {
                handlePostBack(GA_EVENT_NAMES.BACK_BUTTON_CTA_ACTION_BUTTON);
              } else if (customCss) {
                setCustomCss(false);
              }
            }}
            className="mt-16 all-btn"
            type="primary"
            danger
          >
            Back
          </Button>
        </Col>
      )}

      {postMainScreen ? (
        <Col span={24}>
          <Button
            className="full-width mt-16"
            size="large"
            onClick={handlePostStyle}
          >
            <Row>
              <Col span={22}>Post Style</Col>
              <Col span={2}>
                <RightOutlined />
              </Col>
            </Row>
          </Button>

          <Button
            className="full-width mt-16"
            size="large"
            onClick={handlePopupSetting}
            type="default"
          >
            <Row>
              <Col span={22} className="d-flex justify-center col-gap-8">
                {!hasAccessTo?.allowPostPopup && (
                  <PremiumIcon className="h-20 w-20" />
                )}
                <span>Popup Setting</span>
              </Col>
              <Col span={2}>
                <RightOutlined />
              </Col>
            </Row>
          </Button>

          <Button
            className="full-width mt-16"
            size="large"
            onClick={handleActionCall}
          >
            <Row>
              <Col span={22}>Call to Action Buttons</Col>
              <Col span={2}>
                <RightOutlined />
              </Col>
            </Row>
          </Button>

          <Card className="full-width mt-16" size="small">
            <Row align="middle">
              <Col span={12}>Post Click Action</Col>
              <Col span={12}>
                <Select
                  className="full-width"
                  value={postModule?.clickAction}
                  onChange={handleClickActionChange}
                  getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                >
                  {map(POST_ACTION, (item) => (
                    <Option value={item?.value} label={item?.label}>
                      {item?.value === POST_ACTION?.POPUP?.value &&
                      !hasAccessTo?.allowPostPopup ? (
                        <div className="d-flex align-center col-gap-4 ">
                          <PremiumIcon className="h-16 leading-1" />
                          <span>{item?.label}</span>
                        </div>
                      ) : (
                        item?.label
                      )}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Card>
          <Button
            className="full-width mt-16"
            size="large"
            onClick={handleCustomCss}
          >
            <Row>
              <Col span={22} className="d-flex justify-center col-gap-8">
                {!hasAccessTo?.allowCustomCss && (
                  <PremiumIcon className="h-20 w-20" />
                )}
                <span>Post Custom CSS</span>
              </Col>
              <Col span={2}>
                <RightOutlined />
              </Col>
            </Row>
          </Button>

          <Button
            className="full-width mt-16"
            size="large"
            onClick={handlePostModeration}
          >
            <Row>
              <Col span={22} className="d-flex justify-center col-gap-8">
                {!hasAccessTo?.allowPostModeration && (
                  <PremiumIcon className="h-20 w-20" />
                )}
                <span>Post Moderation</span>
              </Col>
              <Col span={2}>
                <RightOutlined />
              </Col>
            </Row>
          </Button>
        </Col>
      ) : (
        <Col span={24}>
          <PostController
            popUpSetting={popUpSetting}
            handleInEditAction={handleInEditAction}
            inEdit={inEdit}
            setInEdit={setInEdit}
            postStyle={postStyle}
            hasAccessTo={hasAccessTo}
            actionCall={actionCall}
            customCss={customCss}
          />
        </Col>
      )}
    </Row>
  );
}

export default WidgetPost;
