import { gql } from '@apollo/client';

const CANCEL_PADDLE_SUBSCRIPTION = gql`
  mutation CancelPaddleCheckoutSubscription {
    cancelPaddleCheckoutSubscription {
      message
    }
  }
`;
export default CANCEL_PADDLE_SUBSCRIPTION;
