import { Button, Tooltip } from 'antd';
import React from 'react';

const PendingButton = ({isDisabled}) => (
  <div>
    <Tooltip title="Please check your payment status after 1 hour.">
      <Button
        type="primary"
        size="large"
        className="buy-button"
        disabled={isDisabled}
      >
        Pending
      </Button>
    </Tooltip>
  </div>
);

export default PendingButton;
