import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Widget from './Widget';

const WidgetWrapper = () => (
  <Switch>
    <Route exact path={`${ROUTES?.WIDGET}/:id`} component={Widget} />
  </Switch>
);

export default WidgetWrapper;
