import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

const InfoTooltip = () => (
  <Tooltip title="In px" className="pointer">
    <QuestionCircleOutlined className="ml-8" />
  </Tooltip>
);

export default InfoTooltip;
