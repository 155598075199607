import { gql } from '@apollo/client';

const WIDGET_LISTING = gql`
  query GetWidgets($pagination: WidgetPaginationInput) {
    getWidgets(pagination: $pagination) {
      count
      data {
        id
        name
        filters {
          show
          hide
          totalNumberOfPost
        }
        createdAt
        updatedAt
        template
        views
        published
      }
    }
  }
`;
export default WIDGET_LISTING;
