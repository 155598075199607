import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import az from './locales/az/translation.json';
import cs from './locales/cs/translation.json';
import da from './locales/da/translation.json';
import de from './locales/de/translation.json';
import el from './locales/el/translation.json';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import fa from './locales/fa/translation.json';
import fi from './locales/fi/translation.json';
import fr from './locales/fr/translation.json';
import he from './locales/he/translation.json';
import hi from './locales/hi/translation.json';
import hr from './locales/hr/translation.json';
import hu from './locales/hu/translation.json';
import it from './locales/it/translation.json';
import ja from './locales/ja/translation.json';
import ko from './locales/ko/translation.json';
import lt from './locales/lt/translation.json';
import nl from './locales/nl/translation.json';
import no from './locales/no/translation.json';
import pl from './locales/pl/translation.json';
import pt from './locales/pt/translation.json';
import ro from './locales/ro/translation.json';
import ru from './locales/ru/translation.json';
import sk from './locales/sk/translation.json';
import sl from './locales/sl/translation.json';
import sv from './locales/sv/translation.json';
import tr from './locales/tr/translation.json';
import uk from './locales/uk/translation.json';
import vi from './locales/vi/translation.json';
import zh from './locales/zh/translation.json';

const resourceObjects = [
  { code: 'en', value: en },
  { code: 'az', value: az },
  { code: 'cs', value: cs },
  { code: 'da', value: da },
  { code: 'de', value: de },
  { code: 'el', value: el },
  { code: 'es', value: es },
  { code: 'fa', value: fa },
  { code: 'fi', value: fi },
  { code: 'fr', value: fr },
  { code: 'he', value: he },
  { code: 'hi', value: hi },
  { code: 'hr', value: hr },
  { code: 'hu', value: hu },
  { code: 'it', value: it },
  { code: 'ja', value: ja },
  { code: 'ko', value: ko },
  { code: 'lt', value: lt },
  { code: 'nl', value: nl },
  { code: 'no', value: no },
  { code: 'pl', value: pl },
  { code: 'pt', value: pt },
  { code: 'ro', value: ro },
  { code: 'ru', value: ru },
  { code: 'sk', value: sk },
  { code: 'sl', value: sl },
  { code: 'sv', value: sv },
  { code: 'tr', value: tr },
  { code: 'uk', value: uk },
  { code: 'vi', value: vi },
  { code: 'zh', value: zh },
];

const resources = resourceObjects?.reduce((acc, lang) => {
  acc[lang?.code] = {
    translation: lang?.value,
  };
  return acc;
}, {});

i18n
  ?.use(LanguageDetector)
  ?.use(initReactI18next)
  ?.init({
    resources,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
