import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Dropdown, Row } from 'antd';
import { find, map } from 'lodash';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  POPUP_LIST,
} from '../../common/constants';
import { GaEvent } from '../../common/utils';
import ActionEditButton from './ActionEditButton';
import PostCustomCSS from './PostCustomCSS';
import PostStyle from './PostStyle';

function PostController({
  popUpSetting,
  handleInEditAction,
  inEdit,
  setInEdit,
  postStyle,
  hasAccessTo = null,
  actionCall,
  customCss,
}) {
  const {
    state: { postModule, previewStyle },
    handlePostModule,
  } = useContext(AppContext);
  const { id: widgetId } = useParams();
  const [actionButtonData, setActionButtonData] = useState([]);
  const [editData, setEditData] = useState(null);
  const handleEdit = (id) => {
    setInEdit(true);
    const currentItem = find(
      postModule?.callToAction,
      (item) => item?.id === id,
    );

    setEditData(currentItem);
  };

  const handleDelete = (id) => {
    const callToActionArray = [...postModule?.callToAction];

    const indexToDelete = callToActionArray?.findIndex(
      (item) => item?.id === id,
    );
    if (indexToDelete !== -1) {
      callToActionArray?.splice(indexToDelete, 1);
    }

    handlePostModule({ ...postModule, callToAction: callToActionArray });
  };

  const changePopUpSettings = (value) => {
    handlePostModule({ ...postModule, popUPSettings: value });
  };

  const items = [
    {
      key: 1,
      label: (
        <>
          <Row>
            <Col span={8}>
              <EditOutlined />
            </Col>
            <Col span={16}>Edit</Col>
          </Row>
        </>
      ),
      onClick: handleEdit,
    },
    {
      key: 2,
      label: (
        <>
          <Row>
            <Col span={8}>
              <DeleteOutlined />
            </Col>
            <Col span={16}>Delete</Col>
          </Row>
        </>
      ),
      onClick: handleDelete,
    },
  ];

  const handleGaEvent = (key) => {
    const parameters = {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: widgetId,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    };
    switch (key) {
      case POPUP_LIST.user.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_USER, parameters);
        break;
      case POPUP_LIST.location.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_LOCATION, parameters);
        break;

      case POPUP_LIST.followButton.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_FOLLOW_BUTTON, parameters);
        break;

      case POPUP_LIST.instagramLink.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_INSTAGRAM_LINK, parameters);
        break;
      case POPUP_LIST.likesCount.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_LIKE_COUNT, parameters);
        break;
      case POPUP_LIST.share.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_SHARE, parameters);
        break;
      case POPUP_LIST.text.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_TEXT, parameters);
        break;
      case POPUP_LIST.date.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_DATE, parameters);
        break;
      case POPUP_LIST.comments.value:
        GaEvent(GA_EVENT_NAMES.SHOW_POPUP_ELEMENT_COMMENTS, parameters);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {postStyle && <PostStyle hasAccessTo={hasAccessTo} />}

      {popUpSetting && (
        <Card className="mr-24 full-width">
          <h3>Popup Elements</h3>
          <div>
            <Checkbox.Group
              onChange={changePopUpSettings}
              value={postModule?.popUPSettings}
            >
              <div>
                {map(POPUP_LIST, (e) => (
                  <div key={e?.id} className="mb-8">
                    <Checkbox
                      value={e?.value}
                      onClick={() => handleGaEvent(e?.value)}
                    >
                      {e?.label}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </Checkbox.Group>
          </div>
        </Card>
      )}

      {!inEdit && !popUpSetting && !postStyle && actionCall && (
        <>
          <p className="action-info full-width">
            The buttons will be displayed on the popup. You can set a specific
            call to action for each post. Call to action buttons encourage your
            visitors to perform the actions you need (buy a product or contact
            you).
          </p>
          {postModule?.callToAction?.map((value) => (
            <div key={value?.id}>
              <Card
                size="small"
                className="full-width mt-8 mb-16 call-to-action-button-wrapper"
              >
                <div className="target-url flex-1">{value?.buttonUrl}</div>
                <Dropdown
                  menu={{
                    items: items.map((item) => ({
                      ...item,
                      onClick: () => item?.onClick(value?.id),
                    })),
                  }}
                  className="pointer"
                >
                  <MoreOutlined />
                </Dropdown>
              </Card>
            </div>
          ))}
          <Button
            onClick={handleInEditAction}
            className="full-width mt-16 all-btn"
            size="large"
            icon={<PlusOutlined />}
          >
            Add button
          </Button>
        </>
      )}

      {customCss && <PostCustomCSS />}

      {inEdit && (
        <>
          <ActionEditButton
            editData={editData}
            setEditData={setEditData}
            setInEdit={setInEdit}
            setActionButtonData={setActionButtonData}
            actionButtonData={actionButtonData}
            handlePostModule={handlePostModule}
            postModule={postModule}
          />
        </>
      )}
    </div>
  );
}

export default PostController;
