import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation UserLogin($input: userLoginInput!) {
    userLogin(input: $input) {
      accessToken
      refreshToken
      message
      user {
        firstName
        lastName
        dateOfBirth
        occupation
        phoneNo
        id
        email
        roles
        authProvider
        authRefData
        authRefId
      }
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation UserLogout {
    userLogout {
      message
    }
  }
`;

export const SIGNUP = gql`
  mutation Signup($details: signUp!) {
    signup(details: $details) {
      user {
        firstName
        lastName
        dateOfBirth
        occupation
        phoneNo
        id
        email
        roles
        authProvider
        authRefData
        authRefId
      }
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ResetRequest($email: String) {
    resetRequest(email: $email) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($data: ResetPassword!) {
    resetPassword(data: $data) {
      message
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation emailPasswordVerifyResetToken($token: String!) {
    emailPasswordVerifyResetToken(data: { token: $token }) {
      message
      data {
        isValidToken
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
      data {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation NewAccessToken($refreshToken: String!) {
    newAccessToken(refreshToken: $refreshToken)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($data: ChangePassword!) {
    changePassword(data: $data) {
      message
    }
  }
`;

export const VERIFY_SIGNUP = gql`
  mutation VerifyUserAccount($data: VerifyUserAccountInput!) {
    verifyUserAccount(data: $data) {
      message
      accessToken
      refreshToken
    }
  }
`;

export const SOCIAL_LOGIN = gql`
  mutation SocialLogin($token: String) {
    socialLogin(token: $token) {
      message
      accessToken
      refreshToken
      user {
        firstName
        lastName
        dateOfBirth
        occupation
        phoneNo
        id
        email
        roles
        authProvider
        authRefData
        authRefId
        isNewUser
      }
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation EditProfile($details: UpdateUserInput!) {
    editProfile(details: $details) {
      message
      details {
        firstName
        lastName
        dateOfBirth
        occupation
        phoneNo
        id
        email
        roles
        authProvider
        authRefData
        authRefId
      }
    }
  }
`;

export const RESEND_OTP = gql`
  mutation ResendVerificationOtp($email: String!) {
    resendVerificationOtp(email: $email) {
      message
    }
  }
`;

export const VERIFY_NEW_EMAIL = gql`
  mutation UpdateEmailAddress($data: UpdateEmailAddressInputType) {
    updateEmailAddress(data: $data) {
      message
      user {
        firstName
        lastName
        dateOfBirth
        occupation
        phoneNo
        id
        email
        roles
        authProvider
        authRefData
        authRefId
        subscriptionFeatures
        subscriptionPlan
        isRegisteredCustomer
        userSubscription
        userUpgradedSubscription {
          key
          status
        }
        userWidgetQuota {
          limit
          usage
        }
        isNewUser
      }
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation VerifyNewEmailAddress($data: VerifyNewEmailAddressInputType) {
    verifyNewEmailAddress(data: $data) {
      message
    }
  }
`;
