import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Frame = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;
  const headNode = contentRef?.contentWindow?.document?.head;

  useEffect(() => {
    if (headNode) {
      // eslint-disable-next-line no-undef
      headNode.innerHTML = document?.head?.innerHTML;
    }
    if (mountNode) {
      mountNode?.classList.add('instaplug-widget-wrapper');
      mountNode?.classList.add('overflow-y-scroll');
    }
  }, [headNode]);

  return (
    <iframe title="frame" id="previewIframe" {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

export default Frame;
