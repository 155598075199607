import { Button, Col, Form, Input, message, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  REGEX,
} from '../../common/constants';
import { formValidatorRules, GaEvent } from '../../common/utils';

const { required } = formValidatorRules;

function ActionEditButton({
  setInEdit,
  handlePostModule,
  postModule = null,
  setEditData,
  editData = null,
}) {
  const {
    state: { previewStyle },
  } = useContext(AppContext);
  const { callToAction = [] } = postModule;
  const [isValueChanged, setIsValueChanged] = useState(false);
  const { id } = useParams();

  const splitUrl = (url) => {
    const splitString = url?.split('/');
    return splitString?.[splitString?.length - 2];
  };

  const buttonAdded = (postLink) => {
    splitUrl(postLink);
    return callToAction?.find(
      (item) => splitUrl(item?.instagramUrl) === splitUrl(postLink),
    );
  };

  const finishEditAction = (submitValues) => {
    setInEdit(false);
    GaEvent(GA_EVENT_NAMES.CALL_TO_ACTION_DONE, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
    if (!isEmpty(buttonAdded(submitValues?.instagramUrl))) {
      return message?.error('Button already added to post.');
    }

    const callToActionInitialData = [...callToAction];
    if (editData) {
      const indexToEdit = callToActionInitialData?.findIndex(
        (item) => item?.id === editData?.id,
      );
      if (indexToEdit !== -1) {
        callToActionInitialData[indexToEdit].instagramUrl =
          submitValues?.instagramUrl;
        callToActionInitialData[indexToEdit].buttonUrl =
          submitValues?.buttonUrl;
        callToActionInitialData[indexToEdit].buttonLabel =
          submitValues?.buttonLabel;
      }
      setEditData(null);
      handlePostModule({
        ...postModule,
        callToAction: callToActionInitialData,
      });
      return;
    }

    handlePostModule({
      ...postModule,
      callToAction: [...callToAction, submitValues],
    });
  };

  const handlePostBack = () => {
    setInEdit(false);
    setEditData(null);
    GaEvent(GA_EVENT_NAMES.BACK_BUTTON_CTA_EDIT_BUTTON, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
      [GA_EVENT_PARAMETERS.LAYOUT_TYPE]: previewStyle,
    });
  };

  const handleGaEvents = (eventName) => {
    GaEvent(eventName, {
      [GA_EVENT_PARAMETERS.WIDGET_ID]: id,
    });
  };

  return (
    <div>
      <Form
        layout="vertical"
        onFinish={finishEditAction}
        initialValues={editData}
        onValuesChange={() => setIsValueChanged(true)}
      >
        <Row>
          <Col
            span={24}
            className="text-color d-flex justify-between align-center"
          >
            <div>
              <h2>Edit Button</h2>
            </div>
            <div className="d-flex col-gap-12 mr-8">
              <Button
                htmlType="submit"
                className="all-btn"
                type="primary"
                disabled={!isValueChanged}
              >
                Done
              </Button>
              <Button
                onClick={handlePostBack}
                className="all-btn"
                type="primary"
                danger
              >
                Back
              </Button>
            </div>
          </Col>

          <Col span={24}>
            <div className="text-color mb-8 mt-8 full-width">
              Instagram Post URL
            </div>
            <Form.Item
              name="instagramUrl"
              rules={[
                {
                  ...required,
                  message: 'Please enter instagram post url',
                },
                {
                  pattern: REGEX?.WEB_URL,
                  message: 'Please enter valid URL',
                },
              ]}
            >
              <Input
                onFocus={() =>
                  handleGaEvents(GA_EVENT_NAMES.INSTAGRAM_POST_URL_CTA)
                }
                size="large"
                type="url"
              />
            </Form.Item>
            <p className="action-info full-width">
              Set the Instagram URL of the target post from your feed you need
              to add the button to.
            </p>
          </Col>
          <Col span={24}>
            <div className="text-color mb-8 mt-8">Button URL</div>
            <Form.Item
              name="buttonUrl"
              rules={[
                {
                  ...required,
                  message: 'Please enter button url',
                },
                {
                  pattern: REGEX?.WEB_URL,
                  message: 'Please enter valid url',
                },
              ]}
            >
              <Input
                onFocus={() => handleGaEvents(GA_EVENT_NAMES.BUTTON_URL_CTA)}
                size="large"
                type="url"
              />
            </Form.Item>
            <p className="action-info">
              Set the button redirect link (product page, contact page)
            </p>
          </Col>
          <Col span={24}>
            <div className="text-color mb-8 mt-8">Button Label</div>
            <Form.Item name="buttonLabel">
              <Input
                onFocus={() => handleGaEvents(GA_EVENT_NAMES.BUTTON_LABEL_CTA)}
                size="large"
              />
            </Form.Item>
            <p className="action-info">
              Set the compelling button text your visitors won't resist.
            </p>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ActionEditButton;
